import { AntenaCrudPage } from 'app/antena/antena/pages/AntenaCrudPage';
import { AntenaGrupoCrudPage } from 'app/antena/grupo/pages/AntenaGrupoCrudPage';
import BillerCrudPage from 'app/biller/pages/BillerCrudPage';
import { DriverIncomePage } from 'app/chofer/income/pages/DriverIncomePage';
import ChoferCrudPage from 'app/chofer/pages/ChoferCrudPage';
import ChoferProfilePage from 'app/chofer/pages/ChoferProfilePage';
import DriverShiftsPage from 'app/chofer/pages/DriverShiftsPage';
import { DriverSettlementDetailPage } from 'app/chofer/settlements/pages/DriverSettlementDetailPage';
import { DriverSettlementsCreatePage } from 'app/chofer/settlements/pages/DriverSettlementsCreatePage';
import { DriverSettlementsPage } from 'app/chofer/settlements/pages/DriverSettlementsPage';
import { DriverTransferReservationPendingSettlement } from 'app/chofer/settlements/pages/DriverTransferReservationPendingSettlement';
import { DriverExpirationPage } from 'app/chofer/vencimientos/pages/DriverExpirationPage';
import ClientBusinessCrudPage from 'app/client/clientBusiness/pages/ClientBusinessCrudPage';
import ClientBusinessProfilePage from 'app/client/clientBusiness/pages/ClientBusinessProfilePage';
import RateCrudPage from 'app/client/clientBusiness/rates/pages/RateCrudPage';
import { ClientPrivateCrudPage } from 'app/client/private/private/pages/ClientPrivateCrudPage';
import { ClientPrivateRatePage } from 'app/client/private/rates/pages/ClientPrivateRatePage';
import FacturadosPage from 'app/facturas/pages/FacturadosPage';
import TollCrudPage from 'app/Toll/pages/TollCrudPage';
import { UserCrudPage } from 'app/user/pages/UserCrudPage';
import VehiculoFabricanteCrudPage from 'app/vehiculos/fabricanteVehiculo/pages/VehiculoFabricanteCrudPage';
import VehiculoModeloCrudPage from 'app/vehiculos/modeloVehiculo/pages/VehiculoModeloCrudPage';
import VehiculoCrudPage from 'app/vehiculos/pages/VehiculoCrudPage';
import VehiculoProfilePage from 'app/vehiculos/pages/VehiculoProfilePage';
import TitularCrudPage from 'app/vehiculos/titulares/pages/TitularCrudPage';
import ViajeTrasladoOpenedPage from 'app/viaje/viajeTraslado/abierto/pages/ViajeTrasladoOpenedPage';
import ViajeTrasladoGestionPage from 'app/viaje/viajeTraslado/gestion/pages/ViajeTrasladoGestionPage';
import { TransferReservationClientBusinessToValidateModifyPage } from 'app/viaje/viajeTraslado/pendienteValidacion/pages/TransferReservationClientBusinessToValidateModifyPage';
import { TransferReservationClientPrivateToValidateModifyPage } from 'app/viaje/viajeTraslado/pendienteValidacion/pages/TransferReservationClientPrivateToValidateModifyPage';
import { ViajePendienteSolicitadoPage } from 'app/viaje/viajeTraslado/pendienteValidacion/pages/ViajePendienteSolicitadoPage';
import { TransferReservationPrivateBatchPage } from 'app/viaje/viajeTraslado/private/pages/TransferReservationPrivateBatchPage';
import { TransferReservationPrivateCopyPage } from 'app/viaje/viajeTraslado/private/pages/TransferReservationPrivateCopyPage';
import { TransferReservationPrivateModifyPage } from 'app/viaje/viajeTraslado/private/pages/TransferReservationPrivateModifyPage';
import { TransferReservationRepetitivePage } from 'app/viaje/viajeTraslado/repetitive/pages/TransferReservationRepetitivePage';
import { TransferReservationRepetitivePrivatePage } from 'app/viaje/viajeTraslado/repetitivePrivate/pages/TransferReservationRepetitivePrivatePage';
import { AnalisisComercialPage } from 'app/viaje/viajeTraslado/statistics/pages/AnalisisComercialPage';
import { AverageSpentPerClient } from 'app/viaje/viajeTraslado/statistics/pages/AverageSpentPerClientPage';
import { ManualAndAutomaticReservationsPerMonth } from 'app/viaje/viajeTraslado/statistics/pages/ManualAndAutomaticReservationsPerMonthPage';
import { PricePerClientPage } from 'app/viaje/viajeTraslado/statistics/pages/PricePerClientPage';
import { ReservationPricePerClient } from 'app/viaje/viajeTraslado/statistics/pages/ReservationPricePerClientPage';
import { ReservationAmountPerClient } from 'app/viaje/viajeTraslado/statistics/pages/ReservationsPerClientPage';
import { TransferReservationAssignedPerDayPage } from 'app/viaje/viajeTraslado/statistics/pages/TransferReservationAssignedPerDayPage';
import { TransferReservationDriverResumeTablePage } from 'app/viaje/viajeTraslado/statistics/pages/TransferReservationDriverResumeTablePage';
import { TransferReservationPerCoordinatorPage } from 'app/viaje/viajeTraslado/statistics/pages/TransferReservationPerCoordinatorPage';
import { TransferReservationPerDayPage } from 'app/viaje/viajeTraslado/statistics/pages/TransferReservationPerDayPage';
import { TransferReservationPeroOperatorAndClientPage } from 'app/viaje/viajeTraslado/statistics/pages/TransferReservationPeroOperatorAndClientPage';
import { TransferReservationPerOperatorPage } from 'app/viaje/viajeTraslado/statistics/pages/TransferReservationPerOperatorPage';

import { CoordinadorChoferPendingSettlement } from 'app/chofer/settlements/pages/CoordinadorChoferPendingSettlement';
import ViajeTrasladoCorreccionContado from 'app/viaje/viajeTraslado/pendienteAuditoria/pages/ViajeTrasladoCorreccionContado';
import { ViajeTrasladoPendienteAuditoriaContadoPage } from 'app/viaje/viajeTraslado/pendienteAuditoria/pages/ViajeTrasladoPendienteAuditoriaContadoPage';
import { TransferReservationClientBulkTripCreation } from 'app/viaje/viajeTraslado/viajeTraslado/pages/TransferReservationClientBulkTripCreation';
import { TransferReservationClientBusinessBatchPage } from 'app/viaje/viajeTraslado/viajeTraslado/pages/TransferReservationClientBusinessBatchPage';
import { TransferReservationClientBusinessCopyPage } from 'app/viaje/viajeTraslado/viajeTraslado/pages/TransferReservationClientBusinessCopyPage';
import { TransferReservationClientBusinessModifyPage } from 'app/viaje/viajeTraslado/viajeTraslado/pages/TransferReservationClientBusinessModifyPage';
import { TransferReservationSingleStoreProvider } from 'app/viaje/viajeTraslado/viajeTraslado/services/TransferReservationSingleStore';
import CrudExample from 'example/pages/CrudExample';
import FormExamplePage from 'forms/pages/FormExamplePage';
import FormSteppersPage from 'forms/pages/FormSteppersPage';
import Header from 'layout/components/Header';
import Sidebar, { SideBarStatus } from 'layout/components/Sidebar/Sidebar';
import ProfilePage from 'profile/pages/ProfilePage';
import React, { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useResponsiveBreakpoint } from 'shared/hooks';
import LiquidacionesPage from '../../app/liquidaciones/pages/LiquidacionesPage';
import ViajeTrasladoDeletePage from '../../app/viaje/viajeTraslado/gestion/pages/ViajeTrasladoDeletePage';
import ViajeTrasladoCorreccionCuantaCoriente from '../../app/viaje/viajeTraslado/pendienteAuditoria/pages/ViajeTrasladoCorrecionCuentaCorriente';
import ViajeTrasladoPendienteAuditoriaPage from '../../app/viaje/viajeTraslado/pendienteAuditoria/pages/ViajeTrasladoPendienteAuditoriaPage';
import ViajeTrasladoPendienteLiquidacionPage from '../../app/viaje/viajeTraslado/pendienteLiquidacion/pages/ViajeTrasladoPendienteLiquidacionPage';
import { ViajeTrasladoDetailPage } from '../../app/viaje/viajeTraslado/viajeTraslado/pages/ViajeTrasladoDetailPage';
import Landing from './Landing';
import ViajeTrasladoCoordinarPage from 'app/viaje/viajeTraslado/coordinar/pages/ViajeTrasladoCoordinarPage';
import ViajeTrasladoBorradoresPage from 'app/viaje/viajeTraslado/borradores/pages/ViajeTrasladoBorradoresPage';
import { NotificationsProvider } from 'shared/context/notificationContext';
import AppUsers from 'app/client/appUsers/pages/AppUsers';

const MainLayout = () => {
  const breakpoint = useResponsiveBreakpoint();
  const [sidebarStatus, setSidebarStatus] = useState<SideBarStatus>({
    platform: 'desktop',
    status: 'collapsed'
  });

  useEffect(() => {
    if (breakpoint === 'xs') {
      setSidebarStatus({ platform: 'mobile', status: 'closed' });
    }
    if (breakpoint === 'sm') {
      setSidebarStatus({ platform: 'desktop', status: 'collapsed' });
    }
  }, [breakpoint]);

  function dismissVisibleSidebar() {
    if (sidebarStatus.platform === 'mobile') {
      setSidebarStatus({ platform: 'mobile', status: 'closed' });
    }
  }

  function toggleCollapse() {
    if ((sidebarStatus.platform = 'desktop')) {
      setSidebarStatus((status) =>
        status.status === 'expanded'
          ? { platform: 'desktop', status: 'collapsed' }
          : { platform: 'desktop', status: 'expanded' }
      );
    }
  }

  return (
    <div className="flex min-h-screen">
      <Sidebar status={sidebarStatus} toggleCollapse={toggleCollapse} />
      <div className="flex flex-1 flex-col overflow-hidden">
        <div
          className="flex-1 overflow-auto"
          onClick={dismissVisibleSidebar}
          style={{
            backgroundColor:
              sidebarStatus.platform === 'mobile' ? 'white' : '#eaedf5'
          }}
        >
          <div className="lg:hidden">
            <Header
              toggleSidebar={(
                e: React.MouseEvent<HTMLButtonElement, MouseEvent>
              ) => {
                setSidebarStatus({ platform: 'mobile', status: 'open' });
                e.stopPropagation();
              }}
            />
          </div>
          <div className="bg-blueGray-100"></div>
          <Switch>
            <Route path="/main/landing">
              <Landing />
            </Route>
            <Route path="/main/example">
              <CrudExample />
            </Route>
            <Route path="/main/profile">
              <ProfilePage />
            </Route>
            <Route path="/main/forms/form">
              <FormExamplePage />
            </Route>
            <Route path="/main/forms/steppers">
              <FormSteppersPage />
            </Route>
            <Route path="/main/chofer/:id">
              <ChoferProfilePage />
            </Route>
            <Route path="/main/chofer">
              <ChoferCrudPage />
            </Route>
            <Route path="/main/driver/incomes">
              <DriverIncomePage />
            </Route>
            <Route path="/main/driver/settlements/create">
              <DriverSettlementsCreatePage />
            </Route>
            <Route path="/main/driver/settlements/:id">
              <DriverSettlementDetailPage asRole="admin" />
            </Route>
            <Route path="/main/driver/settlements">
              <DriverSettlementsPage asRole="admin" />
            </Route>
            <Route path="/main/driver/me/travels-pending-settlement">
              <DriverTransferReservationPendingSettlement />
            </Route>
            <Route path="/main/driver/me/pending-settlement-managment">
              <CoordinadorChoferPendingSettlement />
            </Route>
            <Route path="/main/driver/me/settlements/:id">
              <DriverSettlementDetailPage asRole="driver" />
            </Route>
            <Route path="/main/driver/me/settlements">
              <DriverSettlementsPage asRole="driver" />
            </Route>

            <Route path="/main/vehiculo/:id">
              <VehiculoProfilePage />
            </Route>

            <Route path="/main/vehiculo">
              <VehiculoCrudPage />
            </Route>
            <Route path="/main/titulares">
              <TitularCrudPage />
            </Route>
            <Route path="/main/fabricantes">
              <VehiculoFabricanteCrudPage />
            </Route>
            <Route path="/main/modelos">
              <VehiculoModeloCrudPage />
            </Route>
            <Route path="/main/billers">
              <BillerCrudPage />
            </Route>
            <Route path="/main/clientBusiness/:clientBusinessId/rates">
              <RateCrudPage />
            </Route>
            <Route path="/main/clientBusiness/:id">
              <ClientBusinessProfilePage />
            </Route>
            <Route path="/main/clientBusiness">
              <ClientBusinessCrudPage />
            </Route>
            <Route path="/main/clientParticular/listado">
              <ClientPrivateCrudPage />
            </Route>
            <Route path="/main/clientParticular/tarifa">
              <ClientPrivateRatePage />
            </Route>
            <Route path="/main/appUsers">
              <AppUsers />
            </Route>
            <Route path="/main/shifts">
              <DriverShiftsPage />
            </Route>
            <Route path="/main/tolls">
              <TollCrudPage />
            </Route>
            <Route path="/main/users">
              <UserCrudPage />
            </Route>
            <Route path="/main/antenas/antena">
              <AntenaCrudPage />
            </Route>
            <Route path="/main/antenas/grupos">
              <AntenaGrupoCrudPage />
            </Route>

            <TransferReservationSingleStoreProvider>
              <Route path="/main/Viaje/create" exact>
                <TransferReservationClientBusinessBatchPage />
              </Route>
              <Route path="/main/Viaje/create/:id" exact>
                <TransferReservationClientBusinessBatchPage isDraftPage />
              </Route>
              <Route path="/main/Viaje/drafts">
                <ViajeTrasladoBorradoresPage />
              </Route>
              <Route path="/main/Viaje/bulk-create">
                <TransferReservationClientBulkTripCreation />
              </Route>
              {/* <Route path="/main/Viaje/traslado/pendiente" exact>
                <ViajeTrasladoPendientesPage />
              </Route> */}
              <Route path="/main/Viaje/traslado/pendiente/coordinar" exact>
                <NotificationsProvider>
                  <ViajeTrasladoCoordinarPage />
                </NotificationsProvider>
              </Route>
              <Route path="/main/Viaje/traslado/enCurso">
                <ViajeTrasladoOpenedPage />
              </Route>
              {/* <Route path="/main/Viaje/traslado/mapa">
                <ViajeTrasladoMapPage />
              </Route> */}
              <Route path="/main/viajes/privado/modificar/:id">
                <TransferReservationPrivateModifyPage />
              </Route>
              <Route path="/main/viajes/privado/copiar/:id">
                <TransferReservationPrivateCopyPage />
              </Route>
              <Route path="/main/viajes/repetitivo/privado">
                <TransferReservationRepetitivePrivatePage />
              </Route>
              <Route path="/main/Viaje/traslado/privado">
                <TransferReservationPrivateBatchPage />
              </Route>
              <Route path="/main/Viaje/traslado/abierto">
                <ViajeTrasladoOpenedPage />
              </Route>
              <Route path="/main/Viaje/traslado/pendiente-auditoria">
                <ViajeTrasladoPendienteAuditoriaPage />
              </Route>
              <Route path="/main/Viaje/traslado/pendiente-auditoria-contado">
                <ViajeTrasladoPendienteAuditoriaContadoPage />
              </Route>
              <Route path="/main/Viaje/traslado/a-corregir">
                <ViajeTrasladoCorreccionCuantaCoriente />
              </Route>
              <Route path="/main/Viaje/traslado/a-corregir-contado">
                <ViajeTrasladoCorreccionContado />
              </Route>
              <Route path="/main/Viaje/traslado/pendiente-liquidacion">
                <ViajeTrasladoPendienteLiquidacionPage />
              </Route>

              <Route path="/main/liquidaciones/tabla">
                <LiquidacionesPage />
              </Route>

              <Route path="/main/facturados/tabla">
                <FacturadosPage />
              </Route>

              <Route path="/main/vencimientos/tabla">
                <DriverExpirationPage />
              </Route>
              <Route path="/main/solicitados/clientBusiness/aprobacion/:id">
                <TransferReservationClientBusinessToValidateModifyPage />
              </Route>
              <Route path="/main/solicitados/clientPrivate/aprobacion/:id">
                <TransferReservationClientPrivateToValidateModifyPage />
              </Route>
              <Route path="/main/solicitados/tabla">
                <ViajePendienteSolicitadoPage />
              </Route>
              <Route path="/main/Viaje/traslado/gestion">
                <ViajeTrasladoGestionPage />
              </Route>
              <Route path="/main/Viaje/traslado/eliminar">
                <ViajeTrasladoDeletePage />
              </Route>
              <Route path="/main/Viaje/traslado/detalle/:id">
                <ViajeTrasladoDetailPage />
              </Route>
              <Route path="/main/viajes/copiar">
                <TransferReservationClientBusinessCopyPage />
              </Route>
              <Route path="/main/viajes/editar">
                <TransferReservationClientBusinessModifyPage />
              </Route>
              <Route path="/main/viajes/repeticion">
                <TransferReservationRepetitivePage />
              </Route>
              <Route path="/main/transfer-reservation/statistics/per-operator">
                <TransferReservationPerOperatorPage />
              </Route>
              <Route path="/main/transfer-reservation/statistics/per-day">
                <TransferReservationPerDayPage />
              </Route>
              <Route path="/main/transfer-reservation/statistics/per-driver">
                <TransferReservationDriverResumeTablePage />
              </Route>
              <Route path="/main/transfer-reservation/statistics/per-coordinator">
                <TransferReservationPerCoordinatorPage />
              </Route>
              <Route path="/main/transfer-reservation/statistics/assigned-per-day">
                <TransferReservationAssignedPerDayPage />
              </Route>
              <Route path="/main/transfer-reservation/statistics/per-operator-and-client">
                <TransferReservationPeroOperatorAndClientPage />
              </Route>
              <Route path="/main/transfer-reservation/statistics/reservation-price-per-client">
                <ReservationPricePerClient />
              </Route>
              <Route path="/main/transfer-reservation/statistics/average-spent-per-client">
                <AverageSpentPerClient />
              </Route>
              <Route path="/main/transfer-reservation/statistics/reservation-amount-per-client">
                <ReservationAmountPerClient />
              </Route>
              <Route path="/main/transfer-reservation/statistics/price-per-client">
                <PricePerClientPage />
              </Route>

              <Route path="/main/transfer-reservation/statistics/reservations-per-month">
                <ManualAndAutomaticReservationsPerMonth />
              </Route>
              <Route path="/main/transfer-reservation/statistics/analisis-comercial">
                <AnalisisComercialPage />
              </Route>
            </TransferReservationSingleStoreProvider>
          </Switch>
        </div>
      </div>
    </div>
  );
};
export default MainLayout;
