import { BillerModel } from 'app/biller/models/BillerModel';
import { BusinessUnitType } from 'app/business/services/useBusinessUnit';
import { DomicilioModel } from 'app/domicilio/models/DomicilioModel';
import moment, { Moment } from 'moment';
import { domicilioInitialValues } from 'shared/components/FormikAddress';
import { VehiculoPartialModel } from '../../vehiculos/models/Vehiculo';
import { ChoferCRUD, ChoferModel, Horario } from './Chofer';
import { ChoferCategoria } from './ChoferCategoria';
import { ChoferIdioma } from './ChoferIdioma';

export interface ChoferFormSchema {
  general: ChoferGeneralFormSchema;
  personal: ChoferPersonalFormSchema;
  turno: ChoferTurnoFormSchema;
  vencimientos: ChoferVencimientosFormSchema;
}

export interface ChoferGeneralFormSchema {
  codigo: number | string;
  nombreCompleto: string;
  proveedores: BillerModel[];
  vehiculo: VehiculoPartialModel;
  categoria: ChoferCategoria;
  tienePosnet: boolean;
  tieneCursoDeDefensa: boolean;
  fechaIngreso: Moment;
  comision: number;
  businessUnit?: BusinessUnitType | null;
}

export interface ChoferPersonalFormSchema {
  foto: File | string;
  dni_front_photo: File | string;
  dni_back_photo: File | string;
  fechaNacimiento: Moment;
  dni: string | number;
  cuit: string | number;
  domicilio: DomicilioModel;
  telefonoEmergencia: string | number;
  celular: string | number;
  mail: string;
  idiomas: ChoferIdioma[];
}

export interface ChoferTurnoFormSchema {
  domicilio: DomicilioModel;
  piso: string;
  dpto: string;
  horariosLunes: Horario[];
  horariosMartes: Horario[];
  horariosMiercoles: Horario[];
  horariosJueves: Horario[];
  horariosViernes: Horario[];
  horariosSabado: Horario[];
  horariosDomingo: Horario[];
}

export interface ChoferVencimientosFormSchema {
  fechaLicencia: Moment;
  licence_front_photo: File | string;
  licence_back_photo: File | string;
  fotoLinti?: File | string;
  vencimientoLinti?: Moment;
  fotoExamenMedico?: File | string;
  vencimientoExamenMedico?: Moment;
  fotoLibretaSanitaria?: File | string;
  vencimientoLibretaSanitaria?: Moment;
}

export function choferModelToFormSchema({
  general,
  personal,
  turno,
  vencimientos
}: ChoferModel): ChoferFormSchema {
  return {
    general: {
      ...general,
      tieneCursoDeDefensa: personal.tieneCursoDeDefensa,
      vehiculo: {
        ...general.vehiculo,
        tieneMicrocentro: general.vehiculo.microcentro !== undefined
      }
    },
    personal: {
      ...personal,
      domicilio: personal.domicilio
    },
    turno: {
      ...turno,
      piso: turno.domicilio.piso || '',
      dpto: turno.domicilio.departamento || ''
    },
    vencimientos: {
      fechaLicencia: vencimientos.fechaLicencia,
      licence_back_photo: vencimientos.licence_back_photo,
      licence_front_photo: vencimientos.licence_front_photo,
      ...(vencimientos.vencimientoLinti && vencimientos.fotoLinti
        ? {
            fotoLinti: vencimientos.fotoLinti,
            vencimientoLinti: vencimientos.vencimientoLinti
          }
        : {}),
      ...(vencimientos.vencimientoExamenMedico && vencimientos.fotoExamenMedico
        ? {
            fotoExamenMedico: vencimientos.fotoExamenMedico,
            vencimientoExamenMedico: vencimientos.vencimientoExamenMedico
          }
        : {}),
      ...(vencimientos.vencimientoLibretaSanitaria &&
      vencimientos.fotoLibretaSanitaria
        ? {
            fotoLibretaSanitaria: vencimientos.fotoLibretaSanitaria,
            vencimientoLibretaSanitaria:
              vencimientos.vencimientoLibretaSanitaria
          }
        : {})
    }
  };
}

export function choferFormSchemaToModel({
  general,
  personal,
  turno,
  vencimientos
}: ChoferFormSchema): ChoferCRUD {
  return {
    general: {
      ...general,
      codigo: Number(general.codigo),
      businessUnit: general.businessUnit
    },
    personal: {
      ...personal,
      domicilio: personal.domicilio,
      tieneCursoDeDefensa: general.tieneCursoDeDefensa,
      dni: Number(personal.dni),
      cuit: Number(personal.cuit)
    },
    turno: {
      ...turno,
      domicilio: turno.domicilio
    },
    vencimientos: {
      fechaLicencia: vencimientos.fechaLicencia,
      licence_back_photo: vencimientos.licence_back_photo,
      licence_front_photo: vencimientos.licence_front_photo,
      ...(vencimientos.vencimientoLinti && vencimientos.fotoLinti
        ? {
            fotoLinti: vencimientos.fotoLinti,
            vencimientoLinti: vencimientos.vencimientoLinti
          }
        : {}),
      ...(vencimientos.vencimientoExamenMedico && vencimientos.fotoExamenMedico
        ? {
            fotoExamenMedico: vencimientos.fotoExamenMedico,
            vencimientoExamenMedico: vencimientos.vencimientoExamenMedico
          }
        : {}),
      ...(vencimientos.vencimientoLibretaSanitaria &&
      vencimientos.fotoLibretaSanitaria
        ? {
            fotoLibretaSanitaria: vencimientos.fotoLibretaSanitaria,
            vencimientoLibretaSanitaria:
              vencimientos.vencimientoLibretaSanitaria
          }
        : {})
    }
  };
}

export function choferGeneralFormSchemaInitialValues(
  vehiculos: VehiculoPartialModel[],
  proveedores: BillerModel[]
): ChoferGeneralFormSchema {
  return {
    codigo: '',
    nombreCompleto: '',
    proveedores: [],
    vehiculo: vehiculos[0],
    categoria: ChoferCategoria.BRONCE,
    tienePosnet: false,
    tieneCursoDeDefensa: false,
    fechaIngreso: moment(),
    comision: 25
  };
}

export function choferPersonalFormSchemaInitialValues(): ChoferPersonalFormSchema {
  return {
    foto: '',
    dni_front_photo: '',
    dni_back_photo: '',
    fechaNacimiento: moment(),
    dni: '',
    cuit: '',
    domicilio: domicilioInitialValues,
    telefonoEmergencia: '',
    celular: '',
    mail: '',
    idiomas: []
  };
}

export function choferTurnoFormSchemaInitialValues(): ChoferTurnoFormSchema {
  return {
    domicilio: domicilioInitialValues,
    piso: '',
    dpto: '',
    horariosLunes: [],
    horariosMartes: [],
    horariosMiercoles: [],
    horariosJueves: [],
    horariosViernes: [],
    horariosSabado: [],
    horariosDomingo: []
  };
}

export function choferVencimientoFormSchemaInitialValues(): ChoferVencimientosFormSchema {
  return {
    fechaLicencia: moment(),
    licence_front_photo: '',
    licence_back_photo: '',
    fotoLinti: '',
    vencimientoLinti: moment(),
    fotoExamenMedico: '',
    vencimientoExamenMedico: moment(),
    fotoLibretaSanitaria: '',
    vencimientoLibretaSanitaria: moment()
  };
}

export function choferFormSchemaInitialValues(
  vehiculos: VehiculoPartialModel[],
  proveedores: BillerModel[]
) {
  return {
    general: choferGeneralFormSchemaInitialValues(vehiculos, proveedores),
    personal: choferPersonalFormSchemaInitialValues(),
    turno: choferTurnoFormSchemaInitialValues(),
    vencimientos: choferVencimientoFormSchemaInitialValues()
  };
}
