import React from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Button, FormikImageUpload } from '@vadiun/react-components';

import { KeyboardDatePicker } from 'formik-material-ui-pickers';
import {
  choferVencimientoFormSchemaInitialValues,
  ChoferVencimientosFormSchema
} from '../../models/ChoferFormSchema';
import { Moment } from 'moment';
import moment from 'moment';
import { Divider } from '@material-ui/core';

interface Props {
  onSubmit: (value: ChoferVencimientosFormSchema) => void;
  onGoBack?: () => void;
  initialValues?: ChoferVencimientosFormSchema;
  submitButtonTitle?: string;
  goBackButtonTitle?: string;
  isDriverEnabledToBusesService?: boolean;
}

const ChoferFormVencimientos = ({
  goBackButtonTitle = 'Volver',
  submitButtonTitle = 'Guardar',
  onSubmit,
  onGoBack,
  initialValues = choferVencimientoFormSchemaInitialValues(),
  isDriverEnabledToBusesService
}: Props) => {
  const Schema = isDriverEnabledToBusesService
    ? Yup.object().shape({
        fechaLicencia: Yup.mixed()
          .required('La fecha de vencimiento es requerida')
          .test({
            test: (date: Moment | undefined | null) => {
              if (date === undefined || date === null) {
                return true;
              }
              return date.isAfter(moment());
            },
            message: 'La fecha debe ser posterior la fecha actual',
            name: 'invalidDate'
          }),
        licence_front_photo: Yup.mixed().required(
          'La foto de la licencia es requerida'
        ),
        licence_back_photo: Yup.mixed().required(
          'La foto de la licencia es requerida'
        ),
        fotoLinti: Yup.mixed().required('La foto de la LINTI es requerida'),
        vencimientoLinti: Yup.mixed()
          .required('La fecha de vencimiento es requerida')
          .test({
            test: (date: Moment | undefined | null) => {
              if (date === undefined || date === null) {
                return true;
              }
              return date.isAfter(moment());
            },
            message: 'La fecha debe ser posterior la fecha actual',
            name: 'invalidDateLinti'
          }),
        fotoExamenMedico: Yup.mixed().required(
          'La foto del examen es requerida'
        ),
        vencimientoExamenMedico: Yup.mixed()
          .required('La fecha de vencimiento es requerida')
          .test({
            test: (date: Moment | undefined | null) => {
              if (date === undefined || date === null) {
                return true;
              }
              return date.isAfter(moment());
            },
            message: 'La fecha debe ser posterior la fecha actual',
            name: 'invalidDateExamen'
          }),
        fotoLibretaSanitaria: Yup.mixed().required(
          'La foto de la libreta es requerida'
        ),

        vencimientoLibretaSanitaria: Yup.mixed()
          .required('La fecha de vencimiento es requerida')
          .test({
            test: (date: Moment | undefined | null) => {
              if (date === undefined || date === null) {
                return true;
              }
              return date.isAfter(moment());
            },
            message: 'La fecha debe ser posterior la fecha actual',
            name: 'invalidDateLibreta'
          })
      })
    : Yup.object().shape({
        fechaLicencia: Yup.mixed()
          .required('La fecha de vencimiento es requerida')
          .test({
            test: (date: Moment | undefined | null) => {
              if (date === undefined || date === null) {
                return true;
              }
              return date.isAfter(moment());
            },
            message: 'La fecha debe ser posterior la fecha actual',
            name: 'invalidDate'
          }),
        licence_front_photo: Yup.mixed().required(
          'La foto de la licencia es requerida'
        ),
        licence_back_photo: Yup.mixed().required(
          'La foto de la licencia es requerida'
        )
      });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Schema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ submitForm, touched, errors }) => (
        <Form className="grid w-full gap-6">
          <div className="flex gap-6">
            <div className="w-1/3">
              <Field
                component={FormikImageUpload}
                className="aspect-w-2 aspect-h-1"
                name="licence_front_photo"
              />
              <p className="mt-2 text-center text-xs text-gray-700">
                Frente licencia
              </p>
            </div>
            <div className="w-1/3">
              <Field
                component={FormikImageUpload}
                className="aspect-w-2 aspect-h-1"
                name="licence_back_photo"
              />
              <p className="mt-2 text-center text-xs text-gray-700">
                Dorso licencia
              </p>
            </div>
          </div>
          <Field
            component={KeyboardDatePicker}
            label="Fecha Vto. Licencia"
            name="fechaLicencia"
            format="DD/MM/YYYY"
            inputVariant="outlined"
          />
          {isDriverEnabledToBusesService ? (
            <>
              <div className="w-full">
                <Divider />
                <p className="mt-8 mb-6 text-gray-400">
                  Información adicional de choferes con disponibilidad para
                  servicios grupales (SG)
                </p>
                <div className="flex gap-10 mt-12 mb-10 ">
                  <div className="w-1/3 border-2 border-gray-300 p-4 rounded-xl">
                    <p className="mb-5 text-left text-xs text-gray-700 h-8">
                      Licencia Nacional de Transporte Interjurisdiccional
                      (LINTI)
                    </p>
                    <Field
                      component={FormikImageUpload}
                      className="aspect-w-2 aspect-h-1"
                      name="fotoLinti"
                    />
                    <div className="mt-4 w-full">
                      <Field
                        component={KeyboardDatePicker}
                        label="Vencimiento LINTI"
                        name="vencimientoLinti"
                        format="DD/MM/YYYY"
                        inputVariant="outlined"
                        className="w-full"
                      />
                    </div>
                  </div>
                  <div className="w-1/3 border-2 border-gray-300 p-4 rounded-xl">
                    <p className="mb-5 text-left text-xs text-gray-700 h-8">
                      Examen médico
                    </p>

                    <Field
                      component={FormikImageUpload}
                      className="aspect-w-2 aspect-h-1"
                      name="fotoExamenMedico"
                    />
                    <div className="mt-4 w-full">
                      <Field
                        component={KeyboardDatePicker}
                        label="Vencimiento examen médico"
                        name="vencimientoExamenMedico"
                        format="DD/MM/YYYY"
                        inputVariant="outlined"
                        className="w-full"
                      />
                    </div>
                  </div>
                  <div className="w-1/3 border-2 border-gray-300 p-4 rounded-xl">
                    <p className="mb-5 text-left text-xs text-gray-700 h-8">
                      Libreta sanitaria
                    </p>
                    <Field
                      component={FormikImageUpload}
                      className="aspect-w-2 aspect-h-1"
                      name="fotoLibretaSanitaria"
                    />
                    <div className="mt-4 w-full">
                      <Field
                        component={KeyboardDatePicker}
                        label="Vencimiento libreta sanitaria"
                        name="vencimientoLibretaSanitaria"
                        format="DD/MM/YYYY"
                        inputVariant="outlined"
                        className="w-full"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : null}
          <div className="mt-8 flex justify-between">
            {onGoBack ? (
              <Button
                type="button"
                variant="light"
                onClick={onGoBack}
                color="red"
              >
                {goBackButtonTitle}
              </Button>
            ) : (
              <div />
            )}
            <Button type="submit" variant="contained" color="green">
              {submitButtonTitle}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ChoferFormVencimientos;
