import { Button, Modal } from '@vadiun/react-components'
import { Field, Form, Formik, FormikProps } from 'formik';
import React, { FC, useEffect, useRef } from 'react'
import { User } from '../models/appUsersModels';
import { makeStyles, MenuItem } from '@material-ui/core';
import { Select, Switch } from 'formik-material-ui';

interface ChangeActiveStatusModalProps {
  user: User;
  isOpen: boolean;
  handleClose: () => void;
  changeVerification: (userId: string, isActive: boolean, authorizedId: number | null) => void;
}
type FormType = { authorizedId: number | null, isActive: boolean };

const useStyles = makeStyles({
  switchBase: {
    color: 'red', 
    '&.Mui-checked': {
      color: 'green', 
    },
    '&.Mui-checked + .MuiSwitch-track': {
      backgroundColor: 'green', 
    },
  },
  track: {
    backgroundColor: 'red',
  },
});

const CustomSwitch = (props) => {
  const classes = useStyles();
  return (
    <Switch
      {...props}
      classes={{
        switchBase: classes.switchBase,
        track: classes.track,
      }}
    />
  );
};

const ChangeActiveStatusModal: FC<ChangeActiveStatusModalProps> = ({user, isOpen, handleClose, changeVerification}) => {
  const formRef = useRef<FormikProps<FormType>>(null);
  const userId = user.id;
  const isActive = user.is_active;
  const authorized = user.authorized;
  const authorizedId = user.authorized_id || null;
  console.log(user);

  useEffect(() => {
    if (isOpen) {
      formRef.current?.resetForm();
    }
  }, [isOpen]);

  return (
    <Modal
    key={JSON.stringify(user)}
    open={isOpen}
    onClose={() => {
      handleClose();
      formRef.current?.resetForm();
    }}
    body={
      <Formik 
        innerRef={formRef}
        initialValues={{authorizedId: authorizedId, isActive: isActive}} 
        onSubmit={async (values)=>{
            changeVerification(userId, values.isActive, values.authorizedId);
            handleClose()
        }}
      >
       {({values, setFieldValue, submitForm, dirty}) => (
         <Form>
          <div className='flex flex-col items-start gap-6'>
            {authorized.length!==0 && <div className='flex flex-col md:flex-row  sm:items-start md:items-center md:gap-4 w-full'>
              <p className='md:whitespace-nowrap'>Seleccione o cambie el autorizante</p>
              <Field 
                disabled={authorized.length<=0}
                component={Select}
                name='authorizedId'
                label='Seleccione un Autorizante'
                value={`${values.authorizedId? values.authorizedId: 0}`}
                fullWidth
                onChange={(e: React.ChangeEvent<{value: unknown}>)=>{
                  setFieldValue('authorizedId', e.target.value);
                }}>
                  <MenuItem key={'no selected'} value={0}>-Sin autorizante-</MenuItem>
                {authorized.map((autorizante) => (
                  <MenuItem key={autorizante.id} value={autorizante.id}>
                    {autorizante.name}
                  </MenuItem>
                ))}
              </Field>
            </div>}
            <div className='flex flex-col md:flex-row  sm:items-start md:items-center  md:gap-14'>
              <p>Acitve o desactive el usuario</p>
              <Field
              component={CustomSwitch}
              name='isVerified'
              checked={values.isActive}
              label='Verificado'
              onChange={(e: React.ChangeEvent<HTMLInputElement>)=>{
                setFieldValue('isActive', e.target.checked);
              }}
              />
            </div>
          </div>
          <div className="mt-8 flex justify-end">
            <Button onClick={submitForm} variant="contained" disabled={!dirty}>
              Guardar
            </Button>
          </div>
        </Form>)}
      </Formik>
    }
  />
  )
}

export default ChangeActiveStatusModal