import axiosInstance, {
  apiExportKairox,
  apiReservations,
  withHeadersInstance
} from './axiosInstance';

// const usersURL = new RegExp(`${environment.backEnd}/users/*`);
// const permissionsURL = new RegExp(`${environment.backEnd}/permissions/*`);
//
// if (!environment.production) {
//   mock.onGet(usersURL).reply(200, db.users);
//   mock.onGet(permissionsURL).reply(200, db.permissions);
// }

export const httpClient = axiosInstance;
export const httpClientWithHeaders = withHeadersInstance;
export const apiExportsClient = apiExportKairox;
export const httpReservation = apiReservations;
