// import { Button } from '@vadiun/react-components'
import PageHeader from 'layout/components/PageHeader';
import React, { useState } from 'react';
import { CREATE, SORT_ASC, SORT_DESC, TABLE } from '../constans';
import PageContainer from 'layout/components/PageContainer';
import AppUsersTable from '../components/AppUsersTable';
import { useSuperMutation, useSuperQuery } from '@vadiun/react-hooks-legacy';
import { appUsersQueries } from '../services/appUsersQueries';
import { User } from '../models/appUsersModels';
import ChangeActiveStatusModal from '../components/ChangeActiveStatusModal';

const AppUsers = () => {
  const AppUsersService = appUsersQueries();
  const [visiblePage, setVisiblePage] = useState(TABLE);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState<string | null>(null);
  const [sort, setSort] = useState<{ sortDir: string; sortBy: string }>({
    sortDir: SORT_ASC,
    sortBy: 'created_at'
  });
  const [userSelected, setUserSelected] = useState<User | undefined>(undefined);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const getAppUsers = useSuperQuery(
    () =>
      AppUsersService.getAppUsers(
        page,
        rowsPerPage,
        search ? encodeURIComponent(search) : null,
        sort.sortBy,
        sort.sortDir
      ),
    {
      showSpinner: true
    },
    [page, rowsPerPage, search, sort]
  );

  const changeActiveStatus = useSuperMutation(
    (userId: string, isActive: boolean, authorizedId: string | null) =>
      AppUsersService.changeActiveStatus(userId, isActive, authorizedId),
    {
      showSpinner: true
    }
  );

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (newRowsPerPage: number) => {
    setRowsPerPage(newRowsPerPage);
  };

  const handleSearchChange = (search: string | null) => {
    setPage(1);
    setSearch(search || null);
  };

  const handleSortChange = (direction: string | null, sortBy: string) => {
    setPage(1);
    setSort({ sortDir: direction === 'desc' ? SORT_DESC : SORT_ASC, sortBy });
  };

  const handleActivationAction = (user: User) => {
    setUserSelected(user);
    setIsModalOpen(true);
  };
  const handleChangeVerification = async (
    userId: string,
    isActive: boolean,
    authorizedId: number | null
  ) => {
    try {
      await changeActiveStatus.mutate(
        userId,
        isActive,
        authorizedId === 0 ? null : authorizedId
      );
    } catch (error) {
      console.error('Error al cambiar el estado activo:', error);
    } finally {
      getAppUsers.reload();
    }
  };

  return (
    <>
      <PageHeader
        title="Cliente CC"
        subtitle={
          visiblePage === TABLE
            ? 'Tabla de usuarios de app'
            : 'Nuevo usuario de app'
        }
        /*    toolbar={
          <>
            {visiblePage === TABLE ? (
              <Button onClick={() => setVisiblePage(CREATE)} variant="light">
                Crear cliente
              </Button>
            ) : (
              <Button onClick={() => setVisiblePage(TABLE)} variant="light">
                Volver
              </Button>
            )}
          </>
        } */
      />
      <PageContainer>
        {visiblePage === TABLE && (
          <AppUsersTable
            users={getAppUsers.data?.docs || []}
            totalItems={getAppUsers.data?.totalDocs || 0}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPage={rowsPerPage}
            activationAction={handleActivationAction}
            onSearchChange={handleSearchChange}
            onSortChange={handleSortChange}
          />
        )}

        {visiblePage === CREATE && <div>create new app user</div>}
      </PageContainer>
      {userSelected && (
        <ChangeActiveStatusModal
          user={userSelected!}
          isOpen={isModalOpen}
          handleClose={() => setIsModalOpen(false)}
          changeVerification={handleChangeVerification}
        />
      )}
    </>
  );
};

export default AppUsers;
