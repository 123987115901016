import { AuthorizedModel } from 'app/client/clientBusiness/Authorized/models/Authorized';
import moment, { Moment } from 'moment';

export interface LiquidacionModel {
  id: number;
  datetime: Moment;
  client: {
    id: number;
    name: string;
    hasPriority: boolean;
  };
  total_transfer_price: number;
  total_expenses: number;
  total_price: number;
  authorized?: string | AuthorizedModel;
  remises_breakdown: {
    lltpais?: number;
    remises?: number;
    envios?: number;
    total?: number;
  } | null;
  buses_breakdown: {
    propios?: number;
    terciarizados?: number;
    furgones?: number;
    envios?: number;
    total?: number;
  } | null;
}

export const liquidacionBuilder = {
  fromBackEnd: (liquidacion): LiquidacionModel => {
    return {
      ...liquidacion,
      datetime: moment(liquidacion.datetime)
    };
  }
};
