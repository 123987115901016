import {
  faAngleDoubleUp,
  faAngleUp,
  faBus,
  faCar,
  faStar,
  faTruck
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  TypeServiceLabelTable,
  ViajeTrasladoTipoServicio
} from 'app/viaje/models';

type Props = {
  data: any;
  index: number;
  isMobileOrTablet: boolean;
};

const ServiceType = ({ data, index, isMobileOrTablet }: Props) => {
  const current = data[index];
  let serviceType = current.serviceType as ViajeTrasladoTipoServicio;

  if ('service_type' in current) {
    serviceType = current.service_type;
  }

  const isIconServiceEjecutivo =
    serviceType === ViajeTrasladoTipoServicio.EJECUTIVO ? (
      <div className="flex gap-1 items-center">
        <FontAwesomeIcon icon={faAngleUp} color="#d1d1d1" />
        <FontAwesomeIcon size="lg" icon={faCar} color="#d1d1d1" />
      </div>
    ) : null;
  const isIconServiceBuses =
    serviceType === ViajeTrasladoTipoServicio.SERVICIOS_GRUPALES ? (
      <div className="flex gap-1 items-center">
        <div className="w-3" />
        <FontAwesomeIcon size="lg" icon={faBus} color="#d1d1d1" />
      </div>
    ) : null;
  const isIconServicePlus =
    serviceType === ViajeTrasladoTipoServicio.UTILITARIO ? (
      <div className="flex gap-1 items-center">
        <div className="w-3" />
        <FontAwesomeIcon size="1x" icon={faTruck} color="#E1D5F2" />
      </div>
    ) : null;

  const getIconValidated = () => {
    if (serviceType === ViajeTrasladoTipoServicio.VIP) {
      return (
        <div className="flex gap-1 items-center">
          <FontAwesomeIcon size="sm" icon={faAngleDoubleUp} color="#D4AF37" />
          <FontAwesomeIcon size="lg" icon={faCar} color="#D4AF37" />
        </div>
      );
    }

    if (serviceType === ViajeTrasladoTipoServicio.EJECUTIVO) {
      return isIconServiceEjecutivo;
    }

    if (serviceType === ViajeTrasladoTipoServicio.SERVICIOS_GRUPALES) {
      return isIconServiceBuses;
    }

    if (serviceType === ViajeTrasladoTipoServicio.UTILITARIO) {
      return isIconServicePlus;
    }

    return (
      <div className="flex gap-1 items-center">
        <FontAwesomeIcon size="lg" icon={faCar} color="#c1c1c1" />
      </div>
    );
  };

  const iconService = getIconValidated();

  return (
    <div className="w-50 flex justify-between text-sm">
      {isMobileOrTablet ? <p>Servicio</p> : null}
      <div className="flex items-center space-x-4">
        {iconService}
        <p className="text-xs">
          {TypeServiceLabelTable[`${serviceType}`]}
        </p>{' '}
        {data[index].hasPriority && (
          <FontAwesomeIcon icon={faStar} color="orange" />
        )}
      </div>
    </div>
  );
};

export default ServiceType;
