import { Table as VadiunTable } from '@vadiun/react-components';
import { ComponentProps } from 'react';

const Table = ({ options, ...props }: ComponentProps<typeof VadiunTable>) => {
  return (
    <VadiunTable
      options={{
        rowsPerPageOptions: [10, 30, 50, 100],
        ...options
      }}
      {...props}
    />
  );
};

export default Table;
