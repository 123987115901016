// @flow
import { CircularProgress } from '@material-ui/core';
import { Button, TableColumn } from '@vadiun/react-components';
import { UseTableQueryResult } from '@vadiun/react-datatable';
import * as React from 'react';
import { Table } from 'shared/components';
import { createDomicilioText } from 'shared/models/DomicilioGoogle';
import { TransferReservationTableTimesCell } from '../../viajeTraslado/components/TransferReservationTableTimesCell';
import { TransferReservationManagmentModel } from '../models/TransferReservationManagment';
import ServiceType from '../../coordinar/components/list/serviceType';

interface Props {
  tableQuery: UseTableQueryResult<TransferReservationManagmentModel[], unknown>;
  onLocationsDetails: (reservation: TransferReservationManagmentModel) => void;
  onDelete: (id: number) => React.ReactNode;
}

export function ViajeTrasladoDeleteTable({
  tableQuery,
  onLocationsDetails,
  onDelete
}: Props) {
  const data = (tableQuery.data ?? []).map((reservation) => ({
    ...reservation,
    schedule: {
      arrival: reservation.reservationDatetime,
      exit: reservation.departureDatetime,
      end: reservation.arrivalDateTime
    }
  }));

  const columns: TableColumn<any>[] = [
    {
      name: 'schedule',
      label: 'Horarios',
      options: {
        customBodyRender: (schedule) => {
          return <TransferReservationTableTimesCell schedule={schedule} />;
        }
      }
    },
    {
      name: 'cliente',
      label: 'Cliente',
      options: {
        customBodyRenderLite: (index) => (
          <span
            className={
              !data[index].pay_with_cash ? 'text-blue-500' : 'text-black'
            }
          >
            {data[index].client.name}
          </span>
        )
      }
    },
    {
      name: 'puntos',
      label: 'Domicilios',
      options: {
        customBodyRenderLite: (index) => {
          return (
            <div className="flex flex-col items-start">
              {data[index].locations.map((location, index) => (
                <div className="mb-1 flex items-start">
                  <div className="flex h-4 w-4 items-center justify-center rounded-full bg-primary-500 p-1 text-white">
                    {index + 1}
                  </div>
                  <span className="ml-1">
                    {createDomicilioText(location.address)}
                  </span>
                </div>
              ))}
              <Button
                shape="circle"
                className="w-full p-2"
                onClick={() => onLocationsDetails(data[index])}
                color="blue"
              >
                <div className="flex items-center gap-1">Info</div>
              </Button>
            </div>
          );
        }
      }
    },
    {
      name: 'coordinationObservation',
      label: 'Obs. Coordinación'
    },
    {
      name: 'serviceType',
      label: 'Servicio',
      options: {
        customBodyRender: (value, { rowIndex: index }) => (
          <ServiceType data={data} index={index} isMobileOrTablet={false} />
        )
      }
    },
    {
      name: 'id',
      label: 'ID'
    },
    {
      name: 'platformId',
      label: 'Plataforma'
    },
    {
      name: 'assigned_driver',
      label: 'Chofer',
      options: {
        customBodyRenderLite: (index) => (
          <div className="flex flex-col items-center">
            <p>{data[index].assigned_driver?.codigo}</p>
            <p className="text-center text-xs">
              {data[index].assigned_driver?.nombreCompleto}
            </p>
          </div>
        )
      }
    },
    {
      name: 'state',
      label: 'Estado'
    },
    {
      label: 'Acciones',
      name: 'id',
      options: {
        customBodyRenderLite: (index) => (
          <div className="flex space-x-2">{onDelete(data[index].id)}</div>
        )
      }
    }
  ];

  return (
    <div className="w-full">
      <Table
        title={
          tableQuery.isFetching && (
            <div className="flex items-center gap-4">
              <CircularProgress size={30} />
              <p className="text-gray-500">Cargando...</p>
            </div>
          )
        }
        data={data}
        options={{
          elevation: 0,
          selectableRows: 'none',
          search: false,
          filter: false,
          sort: false,
          ...tableQuery.serverSideOptions
        }}
        columns={columns}
      />
    </div>
  );
}
