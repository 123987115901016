import React from 'react';
import { Field, Form, Formik } from 'formik';
import { FormikImageUpload, Button } from '@vadiun/react-components';
import { RadioGroup, Select, TextField } from 'formik-material-ui';
import {
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  TextField as TextFieldMaterial
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DayJsUtils from '@date-io/moment';
import { KeyboardDatePicker } from 'formik-material-ui-pickers';
import {
  Autocomplete,
  AutocompleteRenderInputParams
} from 'formik-material-ui-lab';
import {
  VehiculoFormSchemaGeneral,
  vehiculoFormSchemaGeneralInitialValues,
  VehiculoCombustible,
  VehiculoTipo,
  VehiculoCategoria,
  VehiculoColor,
  VehiculoModeloModel
} from 'app/vehiculos/models';
import { TitularModel } from 'app/vehiculos/titulares/models';
import * as Yup from 'yup';
import { YupShape } from 'shared/models/YupShape';
import ServiceGroupVehicle from './ServiceGroupVehicle';
import { BusType } from 'app/viaje/viajeTraslado/viajeTraslado/models/TransferReservation';

interface Props {
  submitButtonTitle?: string;
  onSubmit: (vehiculoSchema: VehiculoFormSchemaGeneral, reset) => void;
  goBackButtonTitle?: string;
  onGoBack?: () => void;
  initialValues?: VehiculoFormSchemaGeneral;
  titulares: TitularModel[];
  modelos: VehiculoModeloModel[];
  tipos: VehiculoTipo[];
  colores: VehiculoColor[];
  optionsBuses: BusType[];
}

const Schema: YupShape<VehiculoFormSchemaGeneral> = Yup.object().shape({
  fotoAuto: Yup.mixed().required('La foto es requerida'),
  fechaIngreso: Yup.mixed().required('La fecha de ingreso es obligatoria'),
  patente: Yup.string().required('La patente es obligatoria'),
  titular: Yup.object().required('El titular es obligatorio'),
  modelo: Yup.mixed().required('El modelo del vehiculo es obligatorio'),
  color: Yup.string().required('El color es obligatorio'),
  anio: Yup.number().required('El año es obligatorio'),
  chasis: Yup.string().required('El chasis es obligatorio'),
  combustible: Yup.string().required('El combustible es obligatorio'),
  tipo: Yup.string().required('El tipo de auto es obligatorio'),
  categoria: Yup.string().required('La categoria es obligatoria'),
  carbono: Yup.number(),
  bus_type_id: Yup.number().nullable()
});

function VehiculoFormInfoGeneral({
  submitButtonTitle = 'Guardar',
  goBackButtonTitle = 'Volver',
  onGoBack,
  onSubmit,
  titulares,
  modelos,
  tipos,
  colores,
  initialValues = vehiculoFormSchemaGeneralInitialValues(titulares, modelos),
  optionsBuses
}: Props) {
  return (
    <Formik
      initialValues={
        {
          ...initialValues,
          isUsedForServicesGroup: initialValues.bus_type_id !== null
        } as any
      }
      validationSchema={Schema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ submitForm, touched, errors, values, setFieldValue }) => (
        <Form className="w-full grid gap-6">
          <div className="flex gap-16">
            <div className="w-1/3">
              <Field
                component={FormikImageUpload}
                className="aspect-w-1 aspect-h-1"
                name="fotoAuto"
              />
            </div>
            <div className="grid gap-1 w-full">
              <MuiPickersUtilsProvider utils={DayJsUtils}>
                <Field
                  name="fechaIngreso"
                  label="Fecha ingreso"
                  inputVariant="outlined"
                  component={KeyboardDatePicker}
                  fullWidth
                  format="DD/MM/YYYY"
                />
              </MuiPickersUtilsProvider>

              <Field
                name="patente"
                label="Patente"
                variant="outlined"
                component={TextField}
                fullWidth
              />
            </div>
          </div>

          <div className="grid grid-cols-2 gap-6">
            <Field
              name="titular"
              component={Autocomplete}
              fullWidth
              getOptionLabel={(titular: TitularModel) => titular.nombre}
              options={titulares}
              renderInput={(params: AutocompleteRenderInputParams) => (
                <TextFieldMaterial
                  {...params}
                  error={touched['titular'] && !!errors['titular']}
                  helperText={errors['titular']}
                  label="Titular"
                  variant="outlined"
                  fullWidth
                />
              )}
            />

            <Field
              name="anio"
              label="Año"
              variant="outlined"
              component={TextField}
              fullWidth
            />
          </div>

          <div className="grid grid-cols-2 gap-6">
            <p className="text-gray-600 font-semibold text-lg col-start-1 col-end-3">
              Características
            </p>

            <Field
              name="modelo"
              component={Autocomplete}
              className="col-span-2"
              options={modelos}
              getOptionLabel={(modelo: VehiculoModeloModel) =>
                modelo.fabricante.nombre + ' - ' + modelo.nombre
              }
              renderInput={(params: AutocompleteRenderInputParams) => (
                <TextFieldMaterial
                  {...params}
                  error={touched['modelo'] && !!errors['modelo']}
                  helperText={errors['modelo']}
                  label="Modelo"
                  variant="outlined"
                />
              )}
            />
            <FormControl variant="outlined" fullWidth>
              <InputLabel>Tipo</InputLabel>
              <Field component={Select} name="tipo" label="Tipo">
                {tipos.map((tipo) => (
                  <MenuItem value={tipo} key={tipo}>
                    {tipo}
                  </MenuItem>
                ))}
              </Field>
            </FormControl>
            <FormControl variant="outlined" fullWidth>
              <InputLabel>Color</InputLabel>
              <Field component={Select} name="color" label="Color">
                {colores.map((color) => (
                  <MenuItem value={color} key={color}>
                    {color}
                  </MenuItem>
                ))}
              </Field>
            </FormControl>
            <Field
              name="chasis"
              label="Chasis"
              variant="outlined"
              component={TextField}
              fullWidth
            />
            <Field
              label="Carbono"
              name="carbono"
              variant="outlined"
              type="number"
              step={0.01}
              component={TextField}
              fullWidth
            />
            <div className="mb-3 mt-3">
              <ServiceGroupVehicle
                hasErrorFieldBusType={
                  touched.bus_type_id && Boolean(errors.bus_type_id)
                }
                optionsBuses={optionsBuses}
                valueFieldBusType={values.bus_type_id}
                onChangeFieldIsServiceGroup={(e) => {
                  setFieldValue('bus_type_id', e.target.value);
                }}
                errorMessageBusType={errors.bus_type_id}
                setFieldValue={setFieldValue}
                busTypeSelected={initialValues.bus_type_id}
              />
            </div>
            <p className="text-gray-600 font-semibold text-lg col-start-1 col-end-3">
              Combustible
            </p>
            <Field component={RadioGroup} name="combustible">
              <div className="flex">
                <FormControlLabel
                  value={VehiculoCombustible.GNC}
                  control={<Radio color="primary" />}
                  label="GNC"
                />
                <FormControlLabel
                  value={VehiculoCombustible.DIESEL}
                  control={<Radio color="primary" />}
                  label="Diesel"
                />
                <FormControlLabel
                  value={VehiculoCombustible.ELECTRICO}
                  control={<Radio color="primary" />}
                  label="Electrico"
                />
                <FormControlLabel
                  value={VehiculoCombustible.NAFTA}
                  control={<Radio color="primary" />}
                  label="Nafta"
                />
                <FormControlLabel
                  value={VehiculoCombustible.HIBRIDO}
                  control={<Radio color="primary" />}
                  label="Hibrido"
                />
              </div>
            </Field>
            <p className="text-gray-600 font-semibold text-lg col-start-1 col-end-3">
              Categoria
            </p>
            <Field component={RadioGroup} name="categoria">
              <div className="flex">
                <FormControlLabel
                  value={VehiculoCategoria.BASICO}
                  control={<Radio color="primary" />}
                  label="Básico"
                />
                <FormControlLabel
                  value={VehiculoCategoria.BRONCE}
                  control={<Radio color="primary" />}
                  label="Bronce"
                />
                <FormControlLabel
                  value={VehiculoCategoria.PLATA}
                  control={<Radio color="primary" />}
                  label="Plata"
                />
                <FormControlLabel
                  value={VehiculoCategoria.ORO}
                  control={<Radio color="primary" />}
                  label="Oro"
                />
              </div>
            </Field>
          </div>
          <div className="flex justify-between mt-8">
            {onGoBack ? (
              <Button
                type="button"
                variant="light"
                onClick={onGoBack}
                color="red"
              >
                {goBackButtonTitle}
              </Button>
            ) : (
              <div />
            )}
            <Button
              onClick={() => submitForm()}
              variant="contained"
              color="green"
            >
              {submitButtonTitle}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default VehiculoFormInfoGeneral;
