import { faPlaneArrival } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Divider, IconButton, Popover } from '@material-ui/core';
import { AirportDetails } from 'app/viaje/viajeTraslado/viajeTraslado/models/TransferReservationPartial';
import React, { FunctionComponent, useState } from 'react';

type InfoPlaneProps = {
  airportDetail: AirportDetails | null;
  idTravel: number;
};

const InfoPlane: FunctionComponent<InfoPlaneProps> = ({
  airportDetail,
  idTravel
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <IconButton
        title="Ver informacion del vuelo"
        size="small"
        aria-label="view more information plane"
        onClick={(e) => {
          e.stopPropagation();
          handleClick(e);
        }}
      >
        <FontAwesomeIcon icon={faPlaneArrival} color="#c1c1c1" />
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        elevation={2}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
        id={id}
        className="rounded-lg"
      >
        <div className="flex w-96 flex-col gap-2 p-4">
          <div className="flex gap-3 items-center">
            <FontAwesomeIcon icon={faPlaneArrival} color="#c1c1c1" size="lg" />
            <div className="flex flex-col">
              <p className="font-semibold">
                {airportDetail?.name_aeroline ?? '-'}
              </p>
              <p className="text-gray-500">
                {airportDetail?.number_flight ?? '-'}
              </p>
            </div>
            <div className="ml-auto text-sm text-gray-400">#{idTravel}</div>
          </div>
          <Divider />
          <div className="flex flex-col gap-4 mt-2">
            {airportDetail?.items?.map((item) => (
              <div className="flex justify-between items-center">
                <div className="flex flex-col">
                  <p className="text-sm font-semibold text-left text-gray-600">
                    {item.name}
                  </p>
                  <p className="text-xs font-normal text-left text-gray-400">
                    {item.description}
                  </p>
                </div>
                <p className="text-lg text-gray-600">x{item.quantity}</p>
              </div>
            ))}
          </div>
        </div>
      </Popover>
    </>
  );
};

export default InfoPlane;
