import { CircularProgress, TextField } from '@material-ui/core';
import { Button, Modal } from '@vadiun/react-components';
import { Query } from '@vadiun/react-hooks-legacy';
import { Field, Form, Formik, FormikProps } from 'formik';
import {
  Autocomplete,
  AutocompleteRenderInputParams
} from 'formik-material-ui-lab';
import React, { useEffect, useRef } from 'react';
import * as Yup from 'yup';
import { ChoferPartialModel } from '../models/Chofer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserTie } from '@fortawesome/free-solid-svg-icons';

interface Props {
  driversQuery: Query<ChoferPartialModel[]>;
  onSelect: (solicitante: ChoferPartialModel) => void;
  isOpen: boolean;
  handleClose: () => void;
}

type FormType = { driver: ChoferPartialModel | null };

const ChoferSearchModal = ({
  driversQuery,
  isOpen,
  handleClose,
  onSelect
}: Props) => {
  const formRef = useRef<FormikProps<FormType>>(null);

  useEffect(() => {
    if (isOpen) {
      formRef.current?.resetForm();
    }
  }, [isOpen]);

  return (
    <Modal
      open={isOpen}
      onClose={() => {
        handleClose();
        formRef.current?.resetForm();
      }}
      body={
        <>
          <div className="flex gap-3 items-center mb-7">
            <FontAwesomeIcon icon={faUserTie} size="2x" color="#8ec3e6" />
            <p>Seleccione un chofer</p>
          </div>
          <Formik<FormType>
            enableReinitialize
            initialValues={{
              driver: null
            }}
            onSubmit={async ({ driver }, formikHelpers) => {
              onSelect(driver!);
              formikHelpers.resetForm();
            }}
            validationSchema={Yup.object().shape({
              driver: Yup.mixed().required('Ingrese un Chofer').nullable()
            })}
            innerRef={formRef}
          >
            {({ touched, errors, submitForm }) => (
              <Form>
                <Field
                  name="driver"
                  component={Autocomplete}
                  className="col-span-2"
                  noOptionsText="No se han encontrado resultados"
                  loadingText="Cargando..."
                  fullWidth
                  options={driversQuery.data || []}
                  getOptionLabel={(option: ChoferPartialModel) =>
                    `${option.codigo} - ${option.nombreCompleto}`
                  }
                  renderInput={(params: AutocompleteRenderInputParams) => (
                    <TextField
                      {...params}
                      error={touched['driver'] && !!errors['driver']}
                      helperText={errors['driver']}
                      label="Chofer"
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {driversQuery.isLoading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        )
                      }}
                    />
                  )}
                />
                <div className="mt-8 flex justify-end">
                  <Button onClick={submitForm} variant="contained">
                    Continuar
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </>
      }
    />
  );
};

export default ChoferSearchModal;
