import { createContext, useContext, useEffect, useState } from 'react';
import { io, Socket } from 'socket.io-client';
import environment from '../../environment/environment';
import { TravelTaked } from 'app/viaje/services/TravelsCordinate';

// Tipos para el contexto
export interface INotificationsCoordinationContext {
  driversRequests: any[];
  travelsRejected: any[];
  travelsTaked: TravelTaked[];
  setTravelsTaked: React.Dispatch<React.SetStateAction<TravelTaked[]>>;
  setDriversRequests: React.Dispatch<React.SetStateAction<any[]>>;
  setTravelsRejected: React.Dispatch<React.SetStateAction<any[]>>;
  getTravelsTaked: () => Promise<void>;
}

// Eventos del socket
enum Events {
  DRIVER_CLOSE_SHIFT = 'driver_close_shift',
  CONNECT = 'connect',
  DISCONNECT = 'disconnect',
  DRIVER_APPROVED_SHIFT = 'driver_approved_shift',
  RESERVATION_REJECTED_BY_DRIVER = 'reservation_rejected_by_driver',
  RESERVATION_TAKES = 'transfer_reservations_take_untake'
}

// Creación del contexto
export const NotificationsCoordinationContext =
  createContext<INotificationsCoordinationContext>({
    driversRequests: [],
    travelsTaked: [],
    setTravelsTaked: () => {},
    setDriversRequests: () => {},
    travelsRejected: [],
    setTravelsRejected: () => {},
    getTravelsTaked: async () => {}
  });

export const NotificationsProvider = ({ children }) => {
  const [driversRequests, setDriversRequests] = useState<any[]>([]);
  const [travelsRejected, setTravelsRejected] = useState<any[]>([]);
  const [travelsTaked, setTravelsTaked] = useState<TravelTaked[]>([]);

  useEffect(() => {
    const socket: Socket = io(environment.notifications, {
      autoConnect: true,
      transports: ['websocket', 'polling'],
      reconnectionAttempts: 5,
      timeout: 10000 // Esperar 10 segundos antes de marcar timeout
    });

    // Funciones para manejar eventos
    const onDriverCloseShift = (data: any) =>
      setDriversRequests((prev) => [...prev, data]);
    const onDriverApprovedShift = (data: any) =>
      setDriversRequests((prev) => prev.filter((req) => req.id !== data.id));
    const onReservationRejectedByDriver = (data: any) =>
      setTravelsRejected((prev) => [...prev, data]);

    const onTakeTravelByUser = (data: any) => {
      setTravelsTaked((prev) =>
        data.action === 'UN_TAKE'
          ? prev.filter(
              (travel) =>
                travel.transfer_reservation_id !== data.transfer_reservation_id
            )
          : [...prev.filter((travel) => travel.user_id !== data.user_id), data]
      );
    };

    // Suscribirse a eventos
    socket.on(Events.DRIVER_CLOSE_SHIFT, onDriverCloseShift);
    socket.on(Events.DRIVER_APPROVED_SHIFT, onDriverApprovedShift);
    socket.on(
      Events.RESERVATION_REJECTED_BY_DRIVER,
      onReservationRejectedByDriver
    );
    socket.on(Events.RESERVATION_TAKES, onTakeTravelByUser);

    socket.on(Events.CONNECT, () => console.log('✅ Conectado a WebSocket'));
    socket.on(Events.DISCONNECT, () =>
      console.log('🔴 Desconectado de WebSocket')
    );

    // Cleanup cuando el componente se desmonta
    return () => {
      socket.off(Events.DRIVER_CLOSE_SHIFT, onDriverCloseShift);
      socket.off(Events.DRIVER_APPROVED_SHIFT, onDriverApprovedShift);
      socket.off(
        Events.RESERVATION_REJECTED_BY_DRIVER,
        onReservationRejectedByDriver
      );
      socket.off(Events.RESERVATION_TAKES, onTakeTravelByUser);
      socket.disconnect();
    };
  }, []); // Solo ejecutarlo una vez

  // Función para obtener viajes tomados
  const getTravelsTaked = async () => {
    try {
      const response = await fetch(
        `${environment.matching}/transfer-reservations-takes`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'X-Api-Key': environment.matchingKeys
          }
        }
      );

      if (!response.ok) throw new Error('Error en la API');

      const result = await response.json();
      setTravelsTaked(result);
    } catch (error) {
      console.error('❌ Error obteniendo travelsTaked:', error);
    }
  };

  return (
    <NotificationsCoordinationContext.Provider
      value={{
        driversRequests,
        travelsTaked,
        setTravelsTaked,
        setDriversRequests,
        travelsRejected,
        setTravelsRejected,
        getTravelsTaked
      }}
    >
      {children}
    </NotificationsCoordinationContext.Provider>
  );
};

export const useNotificationsContext = () => {
  return useContext(NotificationsCoordinationContext);
};
