import { Divider, Drawer, IconButton } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Button } from '@vadiun/react-components';
import { useTableQuery } from '@vadiun/react-datatable';
import { useFilters } from 'app/viaje/viajeTraslado/viajeTraslado/services/useFilters';
import PageContainer from 'layout/components/PageContainer';
import PageHeader from 'layout/components/PageHeader';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ViajeTrasladoBaseFilters from '../../viajeTraslado/components/ViajeTrasladoBaseFilters';
import ViajeTrasladoResumenModal from '../../viajeTraslado/components/ViajeTrasladoResumenModal';
import { useTransferReservationStore } from '../../viajeTraslado/services/TransferReservationSingleStore';
import useViajesTraslado from '../../viajeTraslado/services/ViajeTrasladoService';
import { ViajeTrasladoGestionTable } from '../components/ViajeTrasladoGestionTable';
import { TransferReservationManagmentModel } from '../models/TransferReservationManagment';
import { ViajeTrasladosGestionEstado } from '../../viajeTraslado/models/TransferReservationPartial';
import ViajeTrasladoInfoCancelado from '../components/ViajeTrasladoInfoCancelado';
import EditLLTPais from '../components/EditLLTPais';
import DownloadTravelsXlsx from '../components/DownloadTravelsXlsx';
import { BusType } from '../../viajeTraslado/models/TransferReservation';
import BusesPreAssignModal from '../../coordinar/components/buses/pre-assign/BusesPreAssignModal';
import useChoferes from 'app/chofer/services/ChoferService';
import { useSuperQuery } from '@vadiun/react-hooks-legacy';
import { ChoferPartialModel } from 'app/chofer/models/Chofer';
import { ViajeTrasladoTipoServicio } from '../../viajeTraslado/models';
import { TransferReservationPendingModel } from '../../pendiente/models/TransferReservationPending';

const ViajeTrasladoGestionPage = () => {
  /*
  !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
  !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
  !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

  Mucho de este componente esta copiado en ViajeTrasladoMapPage.
  Si se hace algun cambio revisar que no se tenga que hacer tambien en ViajeTrasladoMapPage.

  !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
  !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
  !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
  */
  const transferReservationCopy = useTransferReservationStore();
  const ViajeTrasladoService = useViajesTraslado();
  const [showFilters, setShowFilters] = useState(false);

  const [filters, setFilters] = useFilters({
    dateFrom: moment().hour(0).minutes(0),
    dateTo: moment().hour(23).minutes(59),
    clientId: null,
    serviceType: 'Todos',
    hasPriority: false,
    passangerName: '',
    street: '',
    locality: '',
    phone: '',
    client_phone: '',
    reservationId: '',
    state: undefined,
    assigned_driver_id: null,
    driverCode: '',
    oficial_cuenta_id: null,
    authorized_id: null,
    cost_center: '',
    buy_order: '',
    sector: '',
    driver_with_close_shift: false,
    departure_time_exceeded: false,
    client_type: 'BusinessClient',
    business_unit: undefined,
    is_event: false,
    event_name: ''
  });

  const [locationsDetail, setLocationsDetail] = useState<
    | {
        isOpen: false;
        details: undefined;
      }
    | {
        isOpen: true;
        details: TransferReservationManagmentModel;
      }
  >({ isOpen: false, details: undefined });

  const tablePagination = useTableQuery(
    [
      'reservas',
      'gestion',
      {
        ...filters,
        dateFrom: filters.dateFrom.format(),
        dateTo: filters.dateTo.format()
      }
    ],
    async (params) => {
      const res = await ViajeTrasladoService.getAll(params, filters);
      return res;
    }
  );

  const [travelCanceledInfo, setCanceledTravelInfo] =
    useState<ViajeTrasladosGestionEstado>();
  const [showTravelCanceledInfo, setShowTravelCanceledInfo] =
    useState<boolean>(false);
  const tavelCanceledInfoDateTime = travelCanceledInfo?.datetime.split(' ');

  const [openEditLLtPais, setOpenEditLLtPais] = useState<boolean>(false);
  const [selectedReservation, setSelectedReservation] = useState<
    TransferReservationManagmentModel | undefined
  >(undefined);

  const [isOpenBusesPreAssignModal, setIsOpenBusesPreAssignModal] =
    useState<boolean>(false);
  const driverRepository = useChoferes();

  const [optionsBuses, setOptionsBuses] = useState<BusType[]>([]);

  const driversQuery = useSuperQuery(driverRepository.getDriversThatCanWork, {
    showSpinner: false,
    automaticFetch: false
  });

  const [isLoadingDriversCanWork, setIsLoadingDriversCanWork] =
    useState<boolean>(false);
  const [driversCanWork, setDriversCanWork] = useState<ChoferPartialModel[]>(
    []
  );

  const loadDriversCanWork = async () => {
    setIsLoadingDriversCanWork(true);
    const driversCanWork = await driverRepository.getDriversThatCanWork();
    setDriversCanWork(driversCanWork);
    setIsLoadingDriversCanWork(false);
  };

  const onCloseBusesPreAssignModal = useCallback(() => {
    setIsOpenBusesPreAssignModal(false);
  }, []);

  const copyReservation = async (
    reservation: TransferReservationManagmentModel
  ) => {
    transferReservationCopy.copy(reservation.id);
  };

  const onCloseShowInfo = () => {
    setShowTravelCanceledInfo(false);
  };

  const getOptionsBuses = useCallback(async () => {
    const buses = await ViajeTrasladoService.getOptionsBuses();
    setOptionsBuses(buses);
  }, [ViajeTrasladoService]);

  useEffect(() => {
    if (!showTravelCanceledInfo) {
      setCanceledTravelInfo(undefined);
    }
  }, [showTravelCanceledInfo]);

  useEffect(() => {
    if (travelCanceledInfo) {
      setShowTravelCanceledInfo(true);
    }
  }, [travelCanceledInfo]);

  useEffect(() => {
    loadDriversCanWork();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getOptionsBuses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PageHeader
        title="Gestión de traslados"
        toolbar={
          <div className="flex gap-4">
            <DownloadTravelsXlsx filters={filters} />
            <Button variant="light" onClick={() => setShowFilters(true)}>
              Filtros
            </Button>
          </div>
        }
      />
      <Drawer
        variant="persistent"
        anchor="right"
        open={showFilters}
        className="max-w-md"
      >
        <div>
          <IconButton onClick={() => setShowFilters(false)}>
            <ChevronRightIcon />
          </IconButton>
          <Divider />
          <ViajeTrasladoBaseFilters
            onSubmit={async (filters) => {
              setFilters(filters);
              setShowFilters(false);
            }}
            initialValues={filters}
            filterByClient
          />
        </div>
      </Drawer>
      <PageContainer lessMargin>
        <div className="w-full">
          <ViajeTrasladoGestionTable
            tableQuery={tablePagination}
            onLocationsDetails={(reservation) =>
              setLocationsDetail({ isOpen: true, details: reservation })
            }
            onReservationCopy={copyReservation}
            onReservationShowDetails={(id) => (
              <Link to={'/main/Viaje/traslado/detalle/' + id}>
                <Button variant="light" color="green">
                  Detalle
                </Button>
              </Link>
            )}
            setCanceledTravelInfo={(info) => {
              setCanceledTravelInfo(info);
            }}
            onOpenEditLLtPais={(reservation) => {
              setOpenEditLLtPais(true);
              setSelectedReservation(reservation);
            }}
            onBusesPreAssign={(travel: TransferReservationPendingModel) => {
              setSelectedReservation(travel);
              setIsOpenBusesPreAssignModal(true);
            }}
          />
        </div>
      </PageContainer>
      {locationsDetail.details && (
        <ViajeTrasladoResumenModal
          isOpen={locationsDetail.isOpen}
          reservation={locationsDetail.details!}
          handleClose={() =>
            setLocationsDetail({ isOpen: false, details: undefined })
          }
        />
      )}

      {travelCanceledInfo ? (
        <ViajeTrasladoInfoCancelado
          travelCanceledInfo={travelCanceledInfo as ViajeTrasladosGestionEstado}
          onCloseShowInfo={onCloseShowInfo}
          showTravelCanceledInfo={showTravelCanceledInfo}
          date={moment(
            tavelCanceledInfoDateTime ? tavelCanceledInfoDateTime[0] : ''
          ).format('D/M/YY')}
          time={tavelCanceledInfoDateTime ? tavelCanceledInfoDateTime[1] : ''}
        />
      ) : null}
      {openEditLLtPais && (
        <EditLLTPais
          isOpen={openEditLLtPais}
          handleClose={() => {
            setOpenEditLLtPais(false);
            setSelectedReservation(undefined);
          }}
          onEditSuccess={() => {
            setOpenEditLLtPais(false);
            setSelectedReservation(undefined);
            tablePagination.refetch();
          }}
          travel={selectedReservation}
        />
      )}

      <BusesPreAssignModal
        isOpen={isOpenBusesPreAssignModal}
        travel={selectedReservation}
        optionsBuses={optionsBuses}
        driversQuery={{
          ...driversQuery,
          data: driversCanWork.filter(
            (driver) =>
              driver?.businessUnit?.name ===
              ViajeTrasladoTipoServicio.SERVICIOS_GRUPALES
          ),
          isLoading: isLoadingDriversCanWork
        }}
        handleClose={onCloseBusesPreAssignModal}
        onSuccesPreAssign={() => tablePagination.refetch()}
      />
    </>
  );
};

export default ViajeTrasladoGestionPage;
