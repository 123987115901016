import React, { FC, useState } from 'react';
import { User } from '../models/appUsersModels';
import { Table } from 'shared/components';
import { Button } from '@vadiun/react-components';
import moment from 'moment';

interface AppUsersTableProps {
  users: User[];
  totalItems: number;
  rowsPerPage: number;
  onChangePage: (page: number, rowsPerPage: number) => void;
  onChangeRowsPerPage: (rowsPerPage: number) => void;
  activationAction: (user: User) => void;
  onSearchChange: (search: string | null) => void;
  onSortChange: (direction: string | null, sortBy: string) => void;
}
const AppUsersTable: FC<AppUsersTableProps> = ({
  users,
  totalItems,
  rowsPerPage,
  onChangePage,
  onChangeRowsPerPage,
  activationAction,
  onSearchChange,
  onSortChange
}) => {
  const [searchValue, setSearchValue] = useState<string>('');

  const handleSearchChange = (search: string | null) => {
    setSearchValue(search || '');
    onSearchChange(search || null);
  };

  const columns = [
    {
      name: 'fullname',
      label: 'Nombre y Apellido',
      options: {
        filter: true
      }
    },
    {
      name: 'createdAt',
      label: 'Fecha de creación',
      options: {
        filter: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          const date = moment.utc(value).format('DD-MM-YYYY hh:mm');
          return <div className="flex">{date}</div>;
        }
      }
    },
    {
      name: 'status',
      label: 'Estado',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'reservations',
      label: 'Cantidad de reservas',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return <div className="flex justify-center">{value}</div>;
        }
      }
    },
    {
      name: 'company.business_name',
      label: 'Empresa',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value: any, { rowIndex: index }) => {
          return <span>{users[index].company.business_name}</span>;
        }
      }
    },
    {
      name: 'is_active',
      label: 'Activo-Inactivo',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return (
            <div
              style={{
                color: value ? 'green' : 'red',
                fontWeight: 'bold',
                opacity: 0.9
              }}
            >
              {value ? 'Activo' : 'Inactivo'}
            </div>
          );
        }
      }
    },
    {
      name: 'actions',
      label: 'Acciones',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: any, { rowIndex: index }) => {
          return (
            <div key={users[index].id} className="space-y-1">
              <Button
                onClick={() => activationAction(users[index])}
                variant="light"
                className="w-full"
                color="blue"
              >
                Activar
              </Button>
            </div>
          );
        }
      }
    }
  ];
  return (
    <div className="w-full">
      <Table
        key={JSON.stringify(users)}
        options={{
          filter: false,
          download: false,
          searchOpen: searchValue !== '',
          searchText: searchValue,
          elevation: 0,
          selectableRows: 'none',
          print: false,
          serverSide: true,
          count: totalItems,
          rowsPerPage: rowsPerPage,
          onChangePage(page: number) {
            onChangePage(page + 1, rowsPerPage);
          },
          onChangeRowsPerPage(rowsPerPage: number) {
            onChangeRowsPerPage(rowsPerPage);
          },
          onSearchChange: handleSearchChange,
          onColumnSortChange: (
            changedColumn: string,
            direction: 'asc' | 'desc'
          ) => {
            onSortChange(direction, changedColumn);
          },
          searchProps: {
            placeholder: 'Buscar por nombre, apellido o empresa...'
          }
        }}
        title={'App users'}
        data={users}
        columns={columns}
      />
    </div>
  );
};

export default AppUsersTable;
