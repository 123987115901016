import moment, { Moment } from 'moment';

export interface Passenger {
  dni: string;
  email: string;
  hasPriority: boolean;
  id: number;
  name: string;
  nationality: string;
  phone: string;
}

interface Location {
  passengers: Passenger[];
}
interface Expense {
  id: number;
  name: string;
  price: number;
  photo: null;
  type: string;
}

export interface TransferReservationALiquidar {
  id: number;
  client: {
    name: string;
    id: number;
  };
  driver: {
    name: string;
    code: number;
    id: number;
  };
  vehicle: {
    patente: string;
    modelo: string;
    marca: string;
  };
  expenses: Expense[];
  service_type: string;
  platformId: null;
  reservationDatetime: Moment;
  requirements: null | {
    costCenter: null | string;
    subCostCenter: string | null;
    buyOrder: null | string;
    employeeNumber: null | string;
    platformApplication: null | string;
    sector: null | string;
    tripNumber: null | string;
    data1: string | null;
    data2: string | null;
    data3: string | null;
  };
  final_prices: {
    waiting_minutes_price: number;
    waiting_minutes: number;
    kilometers: number;
    out_site_price: number;
    km_price: number;
    km_price_rate_type: number;
    night_plus_price: number;
    rate_type: string;
    type: number;
    fixed_price_name: null;
    expenses_price: number;
    administrative_commission: number;
    driver_commission: number;
    observation: string;
    transfer_price: number;
    driver_price: number;
  };
  locations: Location[];
  fixed_price_name: null;
  sentPlatform: boolean;
  lltpais?: any;
  is_event?: boolean;
  event_name?: string;
}

export const transferReservationALiquidarBuilder = {
  fromBackEnd: (reservation): TransferReservationALiquidar => ({
    ...reservation,
    reservationDatetime: moment(reservation.reservationDatetime)
  })
};
