import { faBus, faUserTie } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  Select
} from '@material-ui/core';
import { Button, Modal } from '@vadiun/react-components';
import { Query } from '@vadiun/react-hooks-legacy';
import { ChoferPartialModel } from 'app/chofer/models/Chofer';
import { useViajesTraslado } from 'app/viaje/services';
import { TransferReservationManagmentModel } from 'app/viaje/viajeTraslado/gestion/models/TransferReservationManagment';
import { TransferReservationPendingModel } from 'app/viaje/viajeTraslado/pendiente/models/TransferReservationPending';
import { BusType } from 'app/viaje/viajeTraslado/viajeTraslado/models/TransferReservation';
import { Field, Form, Formik, FormikProps } from 'formik';
import { CheckboxWithLabel } from 'formik-material-ui';
import {
  Autocomplete,
  AutocompleteRenderInputParams
} from 'formik-material-ui-lab';
import { useSnackbar } from 'notistack';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import FormikOnFieldChange from 'shared/components/FormikOnFieldChange';
import * as Yup from 'yup';

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  travel:
    | TransferReservationPendingModel
    | TransferReservationManagmentModel
    | undefined;
  driversQuery: Query<ChoferPartialModel[]>;
  optionsBuses: BusType[];
  onSuccesPreAssign: () => void;
};

type FormType = {
  driver?: ChoferPartialModel | null;
  bus_type_id: number | null;
  provider_price: number | null;
  customer_price: number | null;
  vehicle_patent: string | null;
  observation?: string;
  is_outsourced?: boolean;
};

const BusesPreAssignDriverSchema = Yup.object().shape({
  driver: Yup.mixed().required('Ingrese un Chofer'),
  bus_type_id: Yup.mixed().required('Seleccione un tipo de bus'),
  customer_price: Yup.number()
    .typeError('Ingrese un número válido')
    .required('Ingrese un precio cliente'),
  provider_price: Yup.number()
    .typeError('Ingrese un número válido')
    .required('Ingrese un precio proveedor'),
  vehicle_patent: Yup.string().required('Ingrese una patente').nullable(),
  observation: Yup.string().nullable()
});

const BusesPreAssignModal: FunctionComponent<Props> = ({
  isOpen,
  handleClose,
  travel,
  driversQuery,
  optionsBuses,
  onSuccesPreAssign
}) => {
  const formRef = useRef<FormikProps<FormType>>(null);
  const { preAssignBusDriver, getBusesByType } = useViajesTraslado();
  const [optionsBusesUnits, setOptionsBusesUnits] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const snackbar = useSnackbar();

  const parseFormValues = (values: FormType) => {
    const dataDraft = { ...values };
    let data = Object.assign({
      ...dataDraft,
      driver_id: dataDraft?.driver?.id,
      vehicle_id: dataDraft.vehicle_patent
    });

    if (data.driver) {
      delete data.driver;
    }

    if (data?.vehicle_patent) {
      delete data.vehicle_patent;
    }

    if (data.observation === null || data.observation === undefined) {
      data.observation = ' ';
    }

    return data;
  };

  const onPreAssignBus = async (values: FormType) => {
    try {
      setIsLoading(true);

      const result = await preAssignBusDriver(
        travel?.id || 0,
        parseFormValues(values)
      );
      if (!result.code) {
        snackbar.enqueueSnackbar('Chofer pre-asignado correctamente!', {
          variant: 'success'
        });
        handleClose();
        onSuccesPreAssign();
      }

      if (result.code) {
        snackbar.enqueueSnackbar(result.messages[0], { variant: 'error' });
      }
    } catch (error: any) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      // formRef.current?.resetForm();
    }
  }, [isOpen]);

  return (
    <Modal
      open={isOpen}
      onClose={() => {
        handleClose();
        formRef.current?.resetForm();
      }}
      size="md"
      body={
        <>
          <div className="flex flex-col mb-8">
            <p className="font-bold text-lg">Pre-asignar chofer de Bus</p>
            <span className="text-gray-400 text-sm">#{travel?.id}</span>
          </div>
          <div className="flex gap-3 items-center mb-4">
            <FontAwesomeIcon icon={faUserTie} size="lg" color="#8ec3e6" />
            <p className="text-gray-600 font-normal">Seleccione un chofer</p>
          </div>
          <Formik<FormType>
            initialValues={{
              driver:
                travel && 'preAssignedDriver' in travel
                  ? travel?.preAssignedDriver
                  : travel && 'pre_assigned_driver' in travel
                  ? travel.pre_assigned_driver
                  : travel && 'assigned_driver' in travel
                  ? travel.assigned_driver
                  : null,
              bus_type_id: travel?.bus_requirement?.bus_type?.id || null,
              provider_price: travel?.bus_requirement?.provider_price ?? null,
              customer_price: travel?.bus_requirement?.customer_price ?? null,
              vehicle_patent: travel?.bus_requirement?.vehicle?.id || null,
              observation: travel?.bus_requirement?.observation || '',
              is_outsourced: travel?.bus_requirement?.is_outsourced || false
            }}
            enableReinitialize
            onSubmit={async (values, formikHelpers) => {
              await onPreAssignBus(values);
              formikHelpers.resetForm();
            }}
            validationSchema={BusesPreAssignDriverSchema}
            innerRef={formRef}
          >
            {({ touched, errors, submitForm, setFieldValue, values }) => (
              <Form className="flex flex-col gap-4">
                <Field
                  name="driver"
                  component={Autocomplete}
                  className="col-span-2"
                  noOptionsText="No se han encontrado resultados"
                  loadingText="Cargando..."
                  fullWidth
                  options={driversQuery.data || []}
                  getOptionLabel={(option: any) => {
                    const code = option.code || option.codigo;
                    const nameComplete = option.name || option.nombreCompleto;
                    return `${code} - ${nameComplete}`;
                  }}
                  renderInput={(params: AutocompleteRenderInputParams) => (
                    <TextField
                      {...params}
                      error={touched['driver'] && !!errors['driver']}
                      helperText={errors['driver']}
                      label="Chofer"
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {driversQuery.isLoading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        )
                      }}
                    />
                  )}
                />
                <div className="flex gap-4">
                  <FormControl
                    variant="outlined"
                    fullWidth
                    className="col-span-1"
                    error={touched.bus_type_id && Boolean(errors.bus_type_id)}
                    disabled
                  >
                    <InputLabel id="bus-type-label">Tipo de bus</InputLabel>
                    <Select
                      value={values?.bus_type_id || ''}
                      name="bus_type_id"
                      labelId="bus-type-label"
                      label="Tipo de bus"
                      displayEmpty
                      onChange={(e) =>
                        setFieldValue('bus_type_id', e.target.value)
                      }
                      disabled
                    >
                      {optionsBuses?.map(({ id: key, name }) => (
                        <MenuItem value={key} key={key}>
                          <div className="flex items-center w-full">
                            <div className="w-6">
                              <FontAwesomeIcon
                                icon={faBus}
                                color="#8EC3E6"
                                size="1x"
                              />
                            </div>
                            <p className="m-0">{name}</p>
                          </div>
                        </MenuItem>
                      ))}
                    </Select>
                    {touched.bus_type_id && errors.bus_type_id && (
                      <p className="text-red-500 text-xs mt-1 ml-3">
                        {errors.bus_type_id}
                      </p>
                    )}
                  </FormControl>
                  <FormikOnFieldChange
                    field="bus_type_id"
                    onChange={async (e) => {
                      if (!e) return;
                      const amountPassengers =
                        optionsBuses.find((item) => item.id === e)
                          ?.amount_passengers ?? 0;
                      const result = await getBusesByType(amountPassengers);
                      setOptionsBusesUnits(
                        result.map((item) => ({
                          id: item.id,
                          name: item.patent,
                          description: item.bus_type.name
                        }))
                      );
                    }}
                  />
                  <FormControl
                    variant="outlined"
                    fullWidth
                    className="col-span-1"
                    error={
                      touched.vehicle_patent && Boolean(errors.vehicle_patent)
                    }
                  >
                    <InputLabel id="patent-bus-type-label">
                      Patente bus
                    </InputLabel>
                    <Select
                      value={values?.vehicle_patent || ''}
                      name="vehicle_patent"
                      label="Patente bus"
                      labelId="patent-bus-type-label"
                      displayEmpty
                      onChange={(e) =>
                        setFieldValue('vehicle_patent', e.target.value)
                      }
                    >
                      {optionsBusesUnits?.map(
                        ({ id: key, name, description }) => (
                          <MenuItem value={key} key={key}>
                            <div className="flex gap-2">
                              <p className="m-0 w-20">{name}</p>
                              <span className="text-gray-400 text-xs">
                                ({description})
                              </span>
                            </div>
                          </MenuItem>
                        )
                      )}
                    </Select>
                    {touched.vehicle_patent && errors.vehicle_patent && (
                      <p className="text-red-500 text-xs mt-1 ml-3">
                        {errors.vehicle_patent}
                      </p>
                    )}
                  </FormControl>
                </div>
                <div className="flex gap-4 w-full">
                  <Field
                    name="provider_price"
                    label="Costo proveedor"
                    variant="outlined"
                    component={TextField}
                    type="number"
                    error={
                      touched.provider_price && Boolean(errors.provider_price)
                    }
                    helperText={touched.provider_price && errors.provider_price}
                    onChange={(e) => {
                      const val = e.target.value;
                      setFieldValue(
                        'provider_price',
                        val === '' ? null : Number(val)
                      );
                    }}
                    value={values.provider_price ?? ''}
                  />
                  <Field
                    name="customer_price"
                    label="Costo cliente"
                    variant="outlined"
                    component={TextField}
                    type="number"
                    error={
                      touched.customer_price && Boolean(errors.customer_price)
                    }
                    helperText={touched.customer_price && errors.customer_price}
                    onChange={(e) => {
                      const val = e.target.value;
                      setFieldValue(
                        'customer_price',
                        val === '' ? null : Number(val)
                      );
                    }}
                    value={values.customer_price ?? ''}
                  />
                </div>
                <div className="flex gap-4">
                  <div className="w-full">
                    <Field
                      component={CheckboxWithLabel}
                      type="checkbox"
                      name="is_outsourced"
                      color="primary"
                      Label={{ label: 'Tercerizado' }}
                    />
                  </div>
                </div>
                <Field
                  name="observation"
                  label="Observaciones"
                  variant="outlined"
                  component={TextField}
                  className="col-start-1 col-end-3"
                  fullWidth
                  error={touched.observation && Boolean(errors.observation)}
                  helperText={touched.observation && errors.observation}
                  onChange={(e) => {
                    const val = e.target.value;
                    setFieldValue(
                      'observation',
                      val === '' ? null : String(val)
                    );
                  }}
                  value={values.observation ?? ''}
                />
                <div className="mt-8 flex justify-end gap-2">
                  <Button onClick={handleClose} variant="text">
                    Cancelar
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    isLoading={isLoading}
                  >
                    Guardar
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </>
      }
    />
  );
};

export default BusesPreAssignModal;
