import { TransferReservationPendingModel } from 'app/viaje/viajeTraslado/pendiente/models/TransferReservationPending';
import { tw } from 'utils/tailwindUtils';
import InfoPlane from './infoPlane';

type Props = {
  current: TransferReservationPendingModel;
  isMobileOrTablet: boolean;
  isCoordinatePage?: boolean;
  clientNormal?: boolean;
};

const Client = ({
  current,
  isMobileOrTablet,
  isCoordinatePage = false,
  clientNormal = false
}: Props) => {
  return (
    <div className="flex flex-col gap-2">
      <div className="flex justify-between text-sm items-center">
        {isMobileOrTablet ? <p>Cliente</p> : null}
        <p
          className={`w-28 text-sm ${
            !current.pay_with_cash && !clientNormal
              ? 'text-blue-500'
              : 'text-black'
          }`}
        >
          {current.client.name}
        </p>

        {current.airport_details && (
          <InfoPlane
            airportDetail={current.airport_details}
            idTravel={current.id}
          />
        )}
      </div>
      <div
        className={tw('hidden flex-col gap-1', {
          flex: current.lltpais ? true : false
        })}
      >
        <div className="flex text-sm w-fit text-blue-100 font-bold px-2 py-1 bg-blue-900 rounded-lg mb-1">
          LLT-País
        </div>
        {!isCoordinatePage ? (
          <>
            <p className="text-light w-36 text-xs leading-none text-gray-400 flex justify-between">
              Proveedor
              <span>${Number(current?.lltpais?.provider_price ?? 0)}</span>
            </p>
            <p className="text-light w-36 text-xs leading-none text-gray-400 flex justify-between">
              Cliente
              <span>${Number(current?.lltpais?.customer_price ?? 0)}</span>
            </p>
          </>
        ) : null}
      </div>
      {!isCoordinatePage ? (
        <div
          className={tw('hidden flex-col gap-1', {
            flex: current.bus_requirement ? true : false
          })}
        >
          <p className="text-light w-36 text-xs leading-none text-gray-400 flex justify-between">
            Proveedor
            <span>
              ${Number(current?.bus_requirement?.provider_price ?? 0)}
            </span>
          </p>
          <p className="text-light w-36 text-xs leading-none text-gray-400 flex justify-between">
            Cliente
            <span>
              ${Number(current?.bus_requirement?.customer_price ?? 0)}
            </span>
          </p>
        </div>
      ) : null}
    </div>
  );
};

export default Client;
