import { useSuperMutation } from '@vadiun/react-hooks-legacy';
import { useViajesTraslado } from 'app/viaje/services';
import { useHistory } from 'react-router-dom';

import { useDistanceAndPriceTransferReservationClientBusinessCalculator } from 'app/viaje/services/distanceAndPriceViajeTrasladoCalculator';
import { ReservaTrasladoFormWrapper } from 'app/viaje/viajeTraslado/viajeTraslado/components/ViajeTrasladoFormWrapper';
import PageContainer from 'layout/components/PageContainer';
import { useCallback, useEffect, useState } from 'react';
import { Card } from 'shared/components';
import { CreatedReservationsModal } from '../components/CreatedReservationsModal';
import TransferReservationBatchForm from '../forms/batch/TransferReservationBatchForm';
import {
  TransferReservationBatchFormType,
  transferReservationBatchFormBuilder
} from '../forms/batch/TransferReservationBatchFormType';
import { useTransferReservationStore } from '../services/TransferReservationSingleStore';
import { BusType } from '../models/TransferReservation';

export const TransferReservationClientBusinessCopyPage = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [optionsBuses, setOptionsBuses] = useState<BusType[]>([]);
  const history = useHistory();
  const ViajeTrasladoService = useViajesTraslado();
  const { calculateDistanceAndCost } =
    useDistanceAndPriceTransferReservationClientBusinessCalculator();

  const addTrasladoMutation = useSuperMutation(
    ViajeTrasladoService.addViajesTraslado,
    { showSpinner: true }
  );

  const { reservation: initialValues } = useTransferReservationStore();

  const parsedInitialValues = {
    ...initialValues!,
    viajes: [
      {
        fechaYHoraDeLlegada: initialValues?.fechaYHoraDeLlegada!,
        minutosPreviosASalida: initialValues?.minutosPreviosASalida!,
        puntos: initialValues?.puntos!
      }
    ]
  };

  async function createViajeTraslado(
    trasladoFormValues: TransferReservationBatchFormType,
    clientBusinessId: number
  ) {
    const ViajeTraslado =
      transferReservationBatchFormBuilder.toModel(trasladoFormValues);
    await addTrasladoMutation.mutate(ViajeTraslado, clientBusinessId);
    setModalOpen(true);
  }

  async function estimatePrice(
    trasladoFormValues: TransferReservationBatchFormType,
    clientBusinessId: number
  ) {
    const estimationsPromise = trasladoFormValues.viajes.map((viaje) =>
      calculateDistanceAndCost(
        {
          arrivalDate: viaje.fechaYHoraDeLlegada,
          hasOutSite: trasladoFormValues.hasOutSite,
          points: viaje.puntos.map((p) => ({
            lat: p.domicilio.latitud,
            lng: p.domicilio.longitud,
            minutesWaiting: Number(p.espera)
          })),
          serviceType: trasladoFormValues.tipoServicio,
          rateName: trasladoFormValues.fixedRate?.name,
          payWithCash: trasladoFormValues.pay_with_cash
        },
        clientBusinessId
      )
    );
    return Promise.all(estimationsPromise);
  }

  const getOptionsBuses = useCallback(async () => {
    const buses = await ViajeTrasladoService.getOptionsBuses();
    setOptionsBuses(buses);
  }, [ViajeTrasladoService]);

  useEffect(() => {
    getOptionsBuses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageContainer>
      <Card className="w-full" title="Nuevo traslado">
        <div className="p-8">
          <ReservaTrasladoFormWrapper<TransferReservationBatchFormType>
            initialValues={parsedInitialValues!}
            onSubmit={createViajeTraslado}
            calculateDistanceAndCost={estimatePrice}
          >
            {(params) => (
              <TransferReservationBatchForm
                optionsBuses={optionsBuses}
                {...params}
              />
            )}
          </ReservaTrasladoFormWrapper>
        </div>
      </Card>
      <CreatedReservationsModal
        open={modalOpen}
        setOpen={setModalOpen}
        reservations={addTrasladoMutation.data?.map((el) => el.id)}
      />
    </PageContainer>
  );
};
