// @flow
import { Chip, TableColumn } from '@vadiun/react-components';
import { Table } from '@vadiun/react-datatable';
import * as React from 'react';
import { TransferReservationALiquidar } from '../../viajeTraslado/models/TransferReservationALiquidar';
import ServiceType from '../../coordinar/components/list/serviceType';
import PassengersItem from 'app/viaje/components/shared/passengers';
import Client from '../../coordinar/components/list/client';
import { TipoServicioLabel } from '../../viajeTraslado/models';

interface Props {
  data: TransferReservationALiquidar[];
  setSelectedRows: (reservations: TransferReservationALiquidar[]) => void;
  selectedReservations: TransferReservationALiquidar[];
  onReservationShowDetails: (
    reservation: TransferReservationALiquidar
  ) => React.ReactNode;
  onPageSizeChange: (pageSize: number) => void;
  currentPageSize: number;
  onPageChange: (page: number) => void;
  totalResults: number;
}

export function ViajeTrasladoPendienteLiquidacionaTable({
  data,
  setSelectedRows,
  selectedReservations,
  onReservationShowDetails,
  onPageSizeChange,
  currentPageSize,
  onPageChange,
  totalResults
}: Props) {
  const adaptedData = data.map((el) => {
    const general_expenses = el.expenses.filter(
      (exp) => exp.type === 'General'
    );
    const toll_expenses = el.expenses.filter((exp) => exp.type === 'Peaje');

    const total_tolls = toll_expenses.reduce(
      (acc, toll) => acc + toll.price,
      0
    );
    const expense_general = general_expenses.reduce(
      (acc, toll) => acc + toll.price,
      0
    );

    return {
      ...el,
      client_name: el.client.name,
      driver_name: `${el.driver?.name} - ${el.driver?.code}`,
      vehicle_name: `${el.vehicle?.patente} - ${el.vehicle?.marca} ${el.vehicle?.modelo}`,
      service_type: el.service_type,
      toll_expenses: toll_expenses
        .map((exp) => `${exp.name} - $${exp.price}`)
        .flat(),
      general_expenses: general_expenses
        .map((exp) => `${exp.name} - $${exp.price}`)
        .flat(),
      reservationDatetime: el.reservationDatetime.format('DD/MM/YYYY'),
      cost_center: el.requirements?.costCenter,
      sub_cost_center: el.requirements?.subCostCenter,
      platform_application: el.requirements?.platformApplication,
      buy_order: el.requirements?.buyOrder,
      sector: el.requirements?.sector,
      amount_km: el.final_prices.kilometers,
      rate_type: el.final_prices.rate_type,
      km_price_rate_type: el.final_prices?.km_price_rate_type,
      km: el.final_prices?.km_price,
      waiting_minutes: el.final_prices?.waiting_minutes,
      waiting_price: el.final_prices?.waiting_minutes_price,
      out_site: el.final_prices?.out_site_price,
      night_plus: el.final_prices?.night_plus_price,
      expense: el.final_prices?.expenses_price,
      expense_toll: total_tolls === 0 ? total_tolls : Math.round(total_tolls),
      expense_general:
        expense_general === 0 ? expense_general : Math.round(expense_general),
      total: el.final_prices?.transfer_price + el.final_prices?.expenses_price,
      passengers: el.locations
        .map((location) =>
          location.passengers.map((passenger) => passenger.name)
        )
        .flat(),
      sentPlatform: el.sentPlatform ? 'Si' : 'No'
    };
  });

  const totalGastos = selectedReservations
    .map((x) => x.final_prices.expenses_price)
    .reduce((a, b) => a + b, 0);
  const totalKm = selectedReservations
    .map((x) => x.final_prices.km_price)
    .reduce((a, b) => a + b, 0);
  const totalEspera = selectedReservations
    .map((x) => x.final_prices.waiting_minutes_price)
    .reduce((a, b) => a + b, 0);
  const total = selectedReservations
    .map((x) => x.final_prices.transfer_price + x.final_prices.expenses_price)
    .reduce((a, b) => a + b, 0);

  const columns: TableColumn<typeof adaptedData[0]>[] = [
    {
      name: 'id',
      label: 'Id'
    },
    {
      name: 'client_name',
      label: 'Cliente',
      options: {
        customBodyRenderLite: (index) => {
          const current = data[index] as any;
          return (
            <Client current={current} isMobileOrTablet={false} clientNormal />
          );
        }
      }
    },
    {
      name: 'reservationDatetime',
      label: 'Fecha'
    },
    {
      name: 'service_type',
      label: 'Tipo de servicio',
      options: {
        display: false
      }
    },
    {
      name: 'driver_name',
      label: 'Chofer - Código',
      options: {
        display: false
      }
    },
    {
      name: 'vehicle_name',
      label: 'Patente - Vehiculo',
      options: {
        display: false
      }
    },
    {
      name: 'platform_application',
      label: 'Sol. Plataforma'
    },
    {
      name: 'platformId',
      label: 'ID Plataforma'
    },
    {
      name: 'service_type',
      label: 'Servicio',
      options: {
        download: false,
        customBodyRender: (value, { rowIndex: index }) => (
          <ServiceType data={data} index={index} isMobileOrTablet={false} />
        )
      }
    },
    {
      name: 'event_name',
      label: 'Evento',
      options: {
        setCellHeaderProps: () => ({
          style: { textAlign: 'center' }
        }),
        customBodyRender: (value, { rowIndex: index }) => (
          <p className="text-gray-700 w-36 text-sm text-center">
            {data[index].event_name}
          </p>
        )
      }
    },
    {
      name: 'cost_center',
      label: 'Centro de costos'
    },
    {
      name: 'sub_cost_center',
      label: 'Sub Centro de costos'
    },
    {
      name: 'buy_order',
      label: 'Orden de compra'
    },
    {
      name: 'sector',
      label: 'Sector'
    },
    {
      name: 'amount_km',
      label: 'Cantidad Kmts',
      options: {
        display: false
      }
    },
    {
      name: 'rate_type',
      label: 'Tarifa',
      options: {
        display: false
      }
    },
    {
      name: 'km_price_rate_type',
      label: 'Precio Tarifa',
      options: {
        display: false
      }
    },
    {
      name: 'km',
      label: 'Km Precio',
      options: {
        display: false
      }
    },
    {
      name: 'waiting_minutes',
      label: 'Espera',
      options: {
        display: false
      }
    },
    {
      name: 'waiting_price',
      label: 'Precio Espera',
      options: {
        display: false
      }
    },
    {
      name: 'passengers',
      label: 'Pasajeros',
      options: {
        download: false,
        customBodyRender: (passengers, { rowIndex: index }) => (
          <PassengersItem passengers={passengers} idTravel={data[index].id} />
        )
      }
    },
    {
      name: 'sentPlatform',
      label: 'Cargado en plataforma',
      options: {
        download: true
      }
    },
    {
      name: 'out_site',
      label: 'Plus-nocturno',
      options: {
        display: false
      }
    },
    {
      name: 'expense_toll',
      label: 'Gastos Peajes',
      options: {
        display: false
      }
    },
    {
      name: 'expense_general',
      label: 'Gastos Otros',
      options: {
        display: false
      }
    },
    {
      name: 'expense',
      label: 'Gastos Totales',
      options: {
        display: false
      }
    },
    {
      name: 'total',
      label: 'Total',
      options: {
        display: false
      }
    },
    {
      name: 'general_expenses',
      label: 'Detalle de Gastos sin peaje',
      options: {
        display: false
      }
    },
    {
      name: 'toll_expenses',
      label: 'Detalle de Peajes',
      options: {
        display: false
      }
    },
    {
      name: 'final_prices',
      label: 'Precios',
      options: {
        download: false,
        customBodyRenderLite: (index) => (
          <div>
            <Chip>
              {`${adaptedData[index].final_prices!.rate_type}` in
              TipoServicioLabel
                ? TipoServicioLabel[
                    `${
                      adaptedData[index].final_prices!.rate_type
                    }` as keyof typeof TipoServicioLabel
                  ]
                : `${adaptedData[index].final_prices!.rate_type}`}
            </Chip>
            <div className="mt-3 grid grid-cols-3 gap-1">
              <span className="col-span-1 mr-2 text-xs text-gray-400">KM</span>
              <span className=" col-span-1 font-semibold text-green-400">
                ${adaptedData[index].km}
              </span>
              <span className="col-span-1 ml-3 text-black">
                {adaptedData[index].final_prices!.kilometers}
              </span>

              <span className="col-span-1 mr-2 text-xs text-gray-400">
                Espera
              </span>
              <span className=" col-span-1 font-semibold text-green-400">
                ${adaptedData[index].waiting_minutes}
              </span>

              <span className="col-span-1 ml-3 text-black">
                {adaptedData[index].final_prices!.waiting_minutes}
              </span>

              <span className="col-span-1 mr-2 text-xs text-gray-400">
                Out-Site
              </span>
              <span className=" col-span-2 font-semibold text-green-400">
                ${adaptedData[index].out_site}
              </span>

              <span className="col-span-1 mr-2 text-xs text-gray-400">
                Plus-nocturno
              </span>
              <span className=" col-span-2 font-semibold text-green-400">
                ${adaptedData[index].night_plus}
              </span>

              <span className="col-span-1 mr-2 text-xs text-gray-400">
                Gastos
              </span>
              <span className=" col-span-2 font-semibold text-red-400">
                ${adaptedData[index].expense}
              </span>
              <span className="col-span-1 mr-2 text-xs text-gray-400">
                Total
              </span>
              <span className=" col-span-2 font-semibold text-blue-400">
                ${adaptedData[index].total}
              </span>
            </div>
          </div>
        )
      }
    },

    {
      label: 'Acciones',
      name: 'id',
      options: {
        download: false,
        customBodyRenderLite: (index) => (
          <div className="space-y-1">
            {onReservationShowDetails(data[index])}
          </div>
        )
      }
    }
  ];

  return (
    <div className="w-full">
      <Table
        data={adaptedData}
        options={{
          elevation: 0,
          search: false,
          rowsPerPage: currentPageSize,
          rowsPerPageOptions: [10, 15, 100, 1000, 2000],
          filter: false,
          sort: false,
          selectableRows: 'multiple',
          selectToolbarPlacement: 'none',
          print: false,
          count: totalResults,
          serverSide: true,
          downloadOptions: {
            filename: 'Liquidaciones_Pendientes.xls'
          },
          onDownload: (buildHeaders, buildBody, columns, data) => {
            if (!selectedReservations.length) {
              return false;
            }
            return (
              buildHeaders(columns) +
              buildBody(
                data.filter(({ data: [id] }) =>
                  selectedReservations.some(({ id: slrId }) => id === slrId)
                )
              )
            );
          },
          onChangePage: (nextPage) => {
            onPageChange(nextPage);
          },
          onChangeRowsPerPage: (nextPageSize) => {
            onPageSizeChange(nextPageSize);
          },
          onRowSelectionChange: (_, selection) =>
            setSelectedRows(selection.map(({ dataIndex }) => data[dataIndex])),
          rowsSelected: selectedReservations.map((selected) =>
            adaptedData.findIndex((d) => d.id === selected.id)
          )
        }}
        title={
          <div className="flex gap-4">
            <p className="text-sm">
              {selectedReservations.length} reservas seleccionadas
            </p>
            <p className="text-sm">
              Gastos: <span className="text-green-500">${totalGastos}</span>
            </p>
            <p className="text-sm">
              Espera: <span className="text-green-500">${totalEspera}</span>
            </p>
            <p className="text-sm">
              Km: <span className="text-green-500">${totalKm}</span>
            </p>
            <p className="text-sm">
              Total: <span className="text-green-500">${total}</span>
            </p>
          </div>
        }
        columns={columns}
      />
    </div>
  );
}
