// @flow
import { CircularProgress } from '@material-ui/core';
import { Button, Chip, TableColumn } from '@vadiun/react-components';
import { UseTableQueryResult } from '@vadiun/react-datatable';
import * as React from 'react';
import { Table } from 'shared/components';
import { createDomicilioText } from 'shared/models/DomicilioGoogle';
import { TransferReservationModel } from '../../viajeTraslado/models/TransferReservation';
import { tw } from 'utils/tailwindUtils';
import ServiceType from '../../coordinar/components/list/serviceType';
interface Props {
  tableQuery: UseTableQueryResult<TransferReservationModel[], unknown>;
  onLocationsDetails: (reservation: TransferReservationModel) => void;
  onCorrectReservationPrices: (reservation: TransferReservationModel) => void;
  onReservationShowDetails: (id: number) => React.ReactNode;
}

export function ViajeTrasladoCorrecionTable({
  tableQuery,
  onLocationsDetails,
  onCorrectReservationPrices,
  onReservationShowDetails
}: Props) {
  const data = tableQuery.data ?? [];

  const columns: TableColumn<TransferReservationModel>[] = [
    {
      name: 'id',
      label: 'Id'
    },
    {
      name: 'client',
      label: 'Cliente',
      options: {
        customBodyRenderLite: (index) => {
          const current = data[index];
          return (
            <div className="flex flex-col gap-2">
              <p
                className={`w-44 text-sm ${
                  !current.pay_with_cash ? 'text-blue-500' : 'text-black'
                }`}
              >
                {current.client.name}
              </p>
              <div
                className={tw('hidden flex-col gap-1', {
                  flex: current.lltpais ? true : false
                })}
              >
                <div className="flex text-sm w-fit text-blue-100 font-bold px-2 py-1 bg-blue-900 rounded-lg mb-1">
                  LLT-País
                </div>
                <p className="text-light w-36 text-xs leading-none text-gray-400 flex justify-between">
                  Proveedor
                  <span>${Number(current?.lltpais?.provider_price ?? 0)}</span>
                </p>
                <p className="text-light w-36 text-xs leading-none text-gray-400 flex justify-between">
                  Cliente
                  <span>${Number(current?.lltpais?.customer_price ?? 0)}</span>
                </p>
              </div>
            </div>
          );
        }
      }
    },
    {
      name: 'reservationDatetime',
      label: 'Fecha',
      options: {
        customBodyRenderLite: (index) =>
          data[index].reservationDatetime.format('DD/MM/YYYY')
      }
    },
    {
      name: 'locations',
      label: 'Domicilios',
      options: {
        customBodyRenderLite: (index) => {
          return (
            <div className="flex flex-col items-start">
              {data[index].locations.map((location, index) => (
                <div className="mb-1 flex items-start">
                  <div className="flex h-4 w-4 items-center justify-center rounded-full bg-primary-500 p-1 text-white">
                    {index + 1}
                  </div>
                  <span className="ml-1">
                    {createDomicilioText(location.address)}
                  </span>
                </div>
              ))}
              <Button
                shape="circle"
                className="w-full p-2"
                onClick={() => onLocationsDetails(data[index])}
                color="blue"
              >
                <div className="flex items-center gap-1">Info</div>
              </Button>
            </div>
          );
        }
      }
    },
    {
      name: 'deviation_observation',
      label: 'Obs. Desvio'
    },
    {
      name: 'deviation_observation',
      label: 'Motivo',
      options: {
        customBodyRenderLite: (index) => (
          <p>{data[index].states[data[index].states.length - 1].observation}</p>
        )
      }
    },
    {
      name: 'requirements',
      label: 'Sol. Plataforma',
      options: {
        customBodyRenderLite: (index) =>
          data[index].requirements?.platformApplication
      }
    },
    {
      name: 'serviceType',
      label: 'Servicio',
      options: {
        customBodyRender: (value, { rowIndex: index }) => (
          <ServiceType data={data} index={index} isMobileOrTablet={false} />
        )
      }
    },

    {
      name: 'final_prices',
      label: 'Precios',
      options: {
        customBodyRenderLite: (index) => (
          <div>
            <Chip>{data[index].final_prices!.rate_type}</Chip>
            <div className="mt-3 grid grid-cols-3 gap-1">
              <span className="col-span-1 mr-2 text-xs text-gray-400">KM</span>
              <span className=" col-span-1 font-semibold text-green-400">
                ${data[index].final_prices!.km_price}
              </span>
              <span className="col-span-1 ml-3 text-black">
                {data[index].final_prices!.kilometers}
              </span>

              <span className="col-span-1 mr-2 text-xs text-gray-400">
                Espera
              </span>
              <span className=" col-span-1 font-semibold text-green-400">
                ${data[index].final_prices!.waiting_minutes_price}
              </span>

              <span className="col-span-1 ml-3 text-black">
                {data[index].final_prices!.waiting_minutes}
              </span>

              <span className="col-span-1 mr-2 text-xs text-gray-400">
                Out-Site
              </span>
              <span className=" col-span-2 font-semibold text-green-400">
                ${data[index].final_prices!.out_site_price}
              </span>

              <span className="col-span-1 mr-2 text-xs text-gray-400">
                Plus-nocturno
              </span>
              <span className=" col-span-2 font-semibold text-green-400">
                ${data[index].final_prices!.night_plus_price}
              </span>

              <span className="col-span-1 mr-2 text-xs text-gray-400">
                Gastos
              </span>
              <span className=" col-span-2 font-semibold text-red-400">
                ${data[index].final_prices!.expenses_price}
              </span>
              <span className="col-span-1 mr-2 text-xs text-gray-400">
                Total
              </span>
              <span className=" col-span-2 font-semibold text-blue-400">
                $
                {data[index].final_prices!.km_price +
                  data[index].final_prices!.waiting_minutes_price +
                  data[index].final_prices!.out_site_price +
                  data[index].final_prices!.night_plus_price +
                  data[index].final_prices!.expenses_price}
              </span>
            </div>
          </div>
        )
      }
    },

    {
      label: 'Acciones',
      name: 'id',
      options: {
        customBodyRenderLite: (index) => (
          <div className="space-y-1">
            {onReservationShowDetails(data[index].id)}
            <Button
              onClick={() => onCorrectReservationPrices(data[index])}
              variant="light"
              className="w-full"
              color="red"
            >
              Corregir
            </Button>
          </div>
        )
      }
    }
  ];

  return (
    <div className="w-full">
      <Table
        title={
          tableQuery.isFetching && (
            <div className="flex items-center gap-4">
              <CircularProgress size={30} />
              <p className="text-gray-500">Cargando...</p>
            </div>
          )
        }
        data={data}
        options={{
          elevation: 0,
          selectableRows: 'none',
          search: false,
          filter: false,
          sort: false,
          print: false,
          download: false,
          ...tableQuery.serverSideOptions
        }}
        columns={columns}
      />
    </div>
  );
}
