import { Button } from '@vadiun/react-components';
import { Query, useVerifyAction } from '@vadiun/react-hooks-legacy';
import { ApplicantModel } from 'app/client/clientBusiness/Applicant/models/ApplicantModel';
import { AuthorizedModel } from 'app/client/clientBusiness/Authorized/models/Authorized';
import { PassengerModel } from 'app/client/clientBusiness/Passenger/models/Passenger';
import {
  ClientBusinessModel,
  ClientBusinessPartialModel
} from 'app/client/clientBusiness/models/ClientBusiness';
import { RateModel } from 'app/client/clientBusiness/rates/models/RateModel';
import { ViajeTrasladoTipoServicio } from 'app/viaje/models';
import {
  ErrorMessage,
  Field,
  FieldArray,
  Form,
  Formik,
  FormikProps
} from 'formik';
import { KeyboardDateTimePicker } from 'formik-material-ui-pickers';
import moment from 'moment';
import { useEffect, useMemo, useRef } from 'react';
import { StaticMap } from 'shared/components';
import { TransferReservationBaseForm } from '../../../viajeTraslado/forms/base/TransferReservationBaseForm';
import { TransferReservationLocationFormModal } from '../../components/TransferReservationLocationFormModal';
import { createTransferReservationSingleFormInitialValues } from './TransferReservationSingleFormInitialValue';
import { TransferReservationSingleFormType } from './TransferReservationSingleFormType';
import { transferReservationSingleFormValidation } from './TransferReservationSingleFormValidation';
import { BusType } from '../../models/TransferReservation';

interface Props {
  initialValues?: TransferReservationSingleFormType;
  onSubmit: (viajeFormValues: TransferReservationSingleFormType, reset) => void;
  getClientesByName: (name: string) => Promise<ClientBusinessPartialModel[]>;
  onClienteSelect: (cliente: ClientBusinessPartialModel) => void;
  solicitantes: ApplicantModel[];
  pasajeros: PassengerModel[];
  autorizadosQuery: Query<AuthorizedModel[]>;
  clientBusiness: ClientBusinessModel | undefined;
  fixedRates: RateModel['categories'][0]['fixedPrices'];
  onServiceTypeSelect: (type: ViajeTrasladoTipoServicio) => void;
  onCalculateDistanceAndCost: (
    viajeFormValues: TransferReservationSingleFormType
  ) => void;
  optionsBuses?: BusType[];
}

export function TransferReservationSingleForm({
  initialValues: _initialValues,
  onSubmit,
  getClientesByName,
  solicitantes,
  pasajeros,
  autorizadosQuery,
  onClienteSelect,
  clientBusiness,
  onServiceTypeSelect,
  onCalculateDistanceAndCost,
  fixedRates,
  optionsBuses
}: Props) {
  const initialValues = useMemo(
    () => _initialValues ?? createTransferReservationSingleFormInitialValues(),
    [_initialValues]
  );
  const formRef = useRef<FormikProps<TransferReservationSingleFormType>>(null);
  const firstLoad = useRef(true);
  const verifyAction = useVerifyAction();

  useEffect(() => {
    if (clientBusiness === undefined) return;
    if (firstLoad.current) {
      firstLoad.current = false;
      return;
    }
    formRef.current?.setFieldValue('prioridad', clientBusiness.hasPriority);
    formRef.current?.setFieldValue('autorizado', undefined);
  }, [clientBusiness]);

  const checkPreviusDate = (viaje) => {
    return moment(viaje.fechaYHoraDeLlegada).isBefore(moment());
  };

  const checkMinutesBeforeDeparture = (viaje) => {
    return moment(viaje.fechaYHoraDeLlegada).isBefore(
      moment(viaje.minutosPreviosASalida)
    );
  };

  return (
    <Formik<TransferReservationSingleFormType>
      innerRef={formRef}
      initialValues={initialValues}
      validationSchema={transferReservationSingleFormValidation(
        clientBusiness,
        formRef.current?.values.isEvent ?? false,
        formRef.current?.values.isLLTPais ?? false
      )}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {(formik) => (
        <Form className="w-full">
          <TransferReservationBaseForm
            {...formik}
            getClientesByName={getClientesByName}
            solicitantes={solicitantes}
            autorizadosQuery={autorizadosQuery}
            onClienteSelect={onClienteSelect}
            clientBusiness={clientBusiness}
            onServiceTypeSelect={onServiceTypeSelect}
            fixedRates={fixedRates}
            optionsBuses={optionsBuses}
          />
          <div className="mt-8 flex space-x-8">
            <div className="flex-grow">
              <div className="flex space-x-8">
                <Field
                  component={KeyboardDateTimePicker}
                  label="Fecha de llegada"
                  name={`fechaYHoraDeLlegada`}
                  ampm={false}
                  inputVariant="outlined"
                  margin="normal"
                  className="flex-1"
                  format="DD/MM/YYYY HH:mm"
                />

                <div className="flex flex-1 flex-col">
                  <Field
                    component={KeyboardDateTimePicker}
                    label="Tiempo previo salida"
                    name={`minutosPreviosASalida`}
                    ampm={false}
                    error={checkMinutesBeforeDeparture(formik.values)}
                    inputVariant="outlined"
                    margin="normal"
                    className="flex-1"
                    format="DD/MM/YYYY HH:mm"
                  />
                  {checkMinutesBeforeDeparture(formik.values) && (
                    <p className="m-0 text-sm text-red-500">
                      La hora de salida debe ser anterior a la hora de llegada
                    </p>
                  )}
                </div>
              </div>

              <FieldArray
                name={`puntos`}
                render={() => (
                  <TransferReservationLocationFormModal
                    pasajeros={pasajeros}
                    puntos={formik.values.puntos}
                    onEditPuntos={(puntos) => {
                      formik.setFieldValue(`puntos`, puntos);
                    }}
                  />
                )}
              />
              <ErrorMessage name={`puntos`}>
                {(msg) => (
                  <p className="mt-4 text-sm text-red-500">
                    {JSON.stringify(msg)}
                  </p>
                )}
              </ErrorMessage>
            </div>
            <div
              className={`w-1/4 transition-all delay-300 duration-300 ease-in-out hover:w-2/5 ${
                formik.values.puntos.length === 0 ? 'opacity-0' : 'opactiy-1'
              }`}
            >
              <StaticMap
                markers={formik.values.puntos.map((punto) => ({
                  lat: punto.domicilio.latitud,
                  lng: punto.domicilio.longitud
                }))}
                size="600x600"
              />
            </div>
          </div>
          <div className="mt-8 flex justify-between">
            <Button
              variant="contained"
              color="primary"
              disabled={formik.values.puntos.length < 2}
              onClick={() => onCalculateDistanceAndCost(formik.values)}
            >
              Calcular costos y distancias
            </Button>

            <Button
              variant="contained"
              color="green"
              className="ml-auto"
              onClick={() => {
                if (!checkPreviusDate(formik.values)) {
                  formik.submitForm();
                } else {
                  verifyAction({
                    onAccept: () => formik.submitForm(),
                    body: '¿Está seguro de crear un viaje con una fecha previa a la de hoy?'
                  });
                }
              }}
            >
              Guardar Viaje
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
