import { faBus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { BusType } from 'app/viaje/viajeTraslado/viajeTraslado/models/TransferReservation';
import { Field } from 'formik';
import { CheckboxWithLabel } from 'formik-material-ui';
import React, { FunctionComponent, useState } from 'react';
import FormikOnFieldChange from 'shared/components/FormikOnFieldChange';

type ServiceGroupVehicleProps = {
  hasErrorFieldBusType: boolean | undefined;
  optionsBuses: BusType[];
  valueFieldBusType: number | null;
  onChangeFieldIsServiceGroup: (
    event: React.ChangeEvent<{ value: unknown }>
  ) => void;
  errorMessageBusType: string | undefined;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  busTypeSelected: number | null;
};

const ServiceGroupVehicle: FunctionComponent<ServiceGroupVehicleProps> = ({
  hasErrorFieldBusType,
  optionsBuses,
  valueFieldBusType,
  onChangeFieldIsServiceGroup,
  errorMessageBusType,
  setFieldValue,
  busTypeSelected
}) => {
  const [isShowServiceGroupField, setIsShowServiceGroupField] =
    useState<boolean>(false);

  const onChangeIsServiceGroup = (value: boolean) => {
    setIsShowServiceGroupField(value);
    if (value) {
      setFieldValue('bus_type_id', busTypeSelected ?? optionsBuses[0].id);
    } else {
      setFieldValue('bus_type_id', null);
    }
  };

  const onChangeBusType = (value: number) => {
    if (value && !isShowServiceGroupField) {
      setFieldValue('isUsedForServicesGroup', true);
    }
  };

  return (
    <>
      <FormikOnFieldChange
        field="isUsedForServicesGroup"
        onChange={onChangeIsServiceGroup}
      />
      <FormikOnFieldChange field="bus_type_id" onChange={onChangeBusType} />
      <Field
        component={CheckboxWithLabel}
        type="checkbox"
        name="isUsedForServicesGroup"
        color="primary"
        className="col-span-1"
        Label={{ label: 'Disponible para servicios grupales (SG)' }}
      />

      {isShowServiceGroupField ? (
        <div className="mt-4">
          <FormControl
            variant="outlined"
            fullWidth
            className="col-span-1"
            error={hasErrorFieldBusType}
          >
            <InputLabel id="bus-type-label">Tipo de bus</InputLabel>
            <Select
              value={valueFieldBusType || ''}
              name="bus_type_id"
              labelId="bus-type-label"
              label="Tipo de bus"
              displayEmpty
              onChange={onChangeFieldIsServiceGroup}
            >
              {optionsBuses.map(({ id: key, name }) => (
                <MenuItem value={key} key={key}>
                  <div className="flex items-center w-full">
                    <div className="w-6">
                      <FontAwesomeIcon icon={faBus} color="#8EC3E6" size="1x" />
                    </div>
                    <p className="m-0">{name}</p>
                  </div>
                </MenuItem>
              ))}
            </Select>
            {hasErrorFieldBusType && (
              <p className="text-red-500 text-xs mt-1 ml-3">
                {errorMessageBusType}
              </p>
            )}
          </FormControl>
        </div>
      ) : null}
    </>
  );
};

export default ServiceGroupVehicle;
