import { httpReservation } from 'shared/services/http/httpClient';
import { appUserModel } from '../models/appUsersModels';

export const appUsersQueries = () => {
  const getAppUsers = async (
    page: number = 1,
    page_size: number = 10,
    search: string | null = null,
    sort: string = 'created_at',
    sort_dir: string = 'ASC'
  ): Promise<appUserModel> => {
    const res = await httpReservation.get('users', {
      params: {
        page,
        page_size,
        search,
        sort,
        sort_dir
      }
    });
    return res;
  };

  const changeActiveStatus = async (
    idUserApp: string,
    isActive: boolean,
    authorizedId: string | null
  ) => {
    return httpReservation.put(`users/${idUserApp}/change-status`, {
      state: isActive,
      authorized_id: authorizedId
    });
  };

  return { getAppUsers, changeActiveStatus };
};
