export const parseVehicleInfoGeneralForm = (data: any) => {
  const dataForm = { ...data };

  if (dataForm.bus_type_id === null || dataForm.bus_type_id === undefined) {
    delete dataForm.bus_type_id;
  }

  delete dataForm.isUsedForServicesGroup;

  return dataForm;
};

export function capitalizeFirstLetter(str: string) {
  if (!str) return '';
  return str.charAt(0).toUpperCase() + str.slice(1);
}
