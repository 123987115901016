import { useSuperMutation } from '@vadiun/react-hooks-legacy';
import { useViajesTraslado } from 'app/viaje/services';
import { useDistanceAndPriceTransferReservationClientBusinessCalculator } from 'app/viaje/services/distanceAndPriceViajeTrasladoCalculator';
import { ReservaTrasladoFormWrapper } from 'app/viaje/viajeTraslado/viajeTraslado/components/ViajeTrasladoFormWrapper';
import PageContainer from 'layout/components/PageContainer';
import { useHistory } from 'react-router-dom';
import { Card } from 'shared/components';
import { TransferReservationSingleForm } from '../forms/single/TransferReservationSingleForm';
import {
  transferReservationSingleFormBuilder,
  TransferReservationSingleFormType
} from '../forms/single/TransferReservationSingleFormType';
import { useTransferReservationStore } from '../services/TransferReservationSingleStore';
import { useCallback, useEffect, useState } from 'react';
import { BusType } from '../models/TransferReservation';

export const TransferReservationClientBusinessModifyPage = () => {
  const history = useHistory();
  const ViajeTrasladoService = useViajesTraslado();
  const { calculateDistanceAndCost } =
    useDistanceAndPriceTransferReservationClientBusinessCalculator();
  const editTrasladoMutation = useSuperMutation(
    ViajeTrasladoService.editViajeTraslado,
    { showSpinner: true }
  );
  const [optionsBuses, setOptionsBuses] = useState<BusType[]>([]);
  const { reservation: initialValues } = useTransferReservationStore();

  async function createViajeTraslado(
    trasladoFormValues: TransferReservationSingleFormType
  ) {
    const ViajeTraslado =
      transferReservationSingleFormBuilder.toModel(trasladoFormValues);
    await editTrasladoMutation.mutate(ViajeTraslado, initialValues!.id);
    history.push('/main/Viaje/traslado/pendiente/coordinar');
  }

  async function estimatePrice(
    trasladoFormValues: TransferReservationSingleFormType,
    clientBusinessId: number
  ) {
    const estimationsPromise = calculateDistanceAndCost(
      {
        arrivalDate: trasladoFormValues.fechaYHoraDeLlegada,
        hasOutSite: trasladoFormValues.hasOutSite,
        points: trasladoFormValues.puntos.map((p) => ({
          lat: p.domicilio.latitud,
          lng: p.domicilio.longitud,
          minutesWaiting: Number(p.espera)
        })),
        serviceType: trasladoFormValues.tipoServicio,
        payWithCash: trasladoFormValues.pay_with_cash
      },
      clientBusinessId
    );
    return [await estimationsPromise];
  }

  const getOptionsBuses = useCallback(async () => {
    const buses = await ViajeTrasladoService.getOptionsBuses();
    setOptionsBuses(buses);
  }, [ViajeTrasladoService]);

  useEffect(() => {
    getOptionsBuses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageContainer>
      <Card className="w-full" title="Editar traslado">
        <div className="p-8">
          <ReservaTrasladoFormWrapper<TransferReservationSingleFormType>
            onSubmit={createViajeTraslado}
            initialValues={initialValues!}
            calculateDistanceAndCost={estimatePrice}
          >
            {(params) => (
              <TransferReservationSingleForm
                optionsBuses={optionsBuses}
                {...params}
              />
            )}
          </ReservaTrasladoFormWrapper>
        </div>
      </Card>
    </PageContainer>
  );
};
