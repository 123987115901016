// @ts-nocheck
import axios from 'axios';
import environment from 'environment/environment';

const instance = axios.create({
  baseURL: environment.backEnd
});

export const withHeadersInstance = axios.create({
  baseURL: environment.backEnd
});

export const apiMatchingKairox = axios.create({
  baseURL: environment.matching
});

export const apiExportKairox = axios.create({
  baseURL: environment.export
});

export const apiReservations = axios.create({
  baseURL: environment.reservation
});

withHeadersInstance.interceptors.request.use(function (config: any) {
  const token = `Bearer ${localStorage.getItem('token')}`;
  config.headers.Authorization = token;
  return config;
});

instance.interceptors.request.use(function (config: any) {
  const token = `Bearer ${localStorage.getItem('token')}`;
  config.headers.Authorization = token;
  return config;
});

instance.interceptors.response.use(
  (response: any) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response.data;
  },
  (error: any) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(new Error(error.response.data.messages.join('. ')));
  }
);

withHeadersInstance.interceptors.response.use(
  (response: any) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  (error: any) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error d
    return Promise.reject(new Error(error.response.data.messages.join('. ')));
  }
);

apiExportKairox.interceptors.request.use(function (config: any) {
  const token = `Bearer ${localStorage.getItem('token')}`;
  config.headers.Authorization = token;
  return config;
});

apiExportKairox.interceptors.response.use(
  (response: any) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  (error: any) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error d
    return Promise.reject(new Error(error.response.data.messages.join('. ')));
  }
);

apiReservations.interceptors.request.use(function (config) {
  const key = environment.reservationApiKey;
  config.headers['x-payment-key'] = key;
  return config;
});

apiReservations.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    return Promise.reject(
      new Error(error?.response?.data?.messages?.join('. ') ?? 'Error')
    );
  }
);

export default instance;
